import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  success: null,
  error: null,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    createTransactionStart: (state) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    createTransactionSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    createTransactionError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetTransactionState: () => initialState,
  },
});

export const {
  createTransactionStart,
  createTransactionSuccess,
  createTransactionError,
  resetTransactionState,
} = transactionSlice.actions;

export default transactionSlice.reducer;