import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  policyContainer: {
    alignItems: 'center',
    paddingVertical: 5,
    marginVertical: 10,
  },
  linksRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  policyLink: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 4,
  },
  linkText: {
    fontSize: 12,
    fontWeight: '400',
  },
  separator: {
    fontSize: 12,
    marginHorizontal: 5,
  },
  dividerLine: {
    height: 1,
    width: '80%', // Adjust width as needed
    marginVertical: 5,
  },
  companyName: {
    fontSize: 10,
    fontWeight: '300',
  },
});