import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, ActivityIndicator } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const PolicyScreen = ({ route }) => {
  const { theme } = useTheme();
  const { colors } = theme;

  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);

  // URLs for each policy type
  const policyUrls = {
    privacy: 'https://console.firebase.google.com/project/tazzo-techcba/storage/tazzo-techcba.appspot.com/files/policy/PrivacyPolicy.json',
    terms: 'https://console.firebase.google.com/project/tazzo-techcba/storage/tazzo-techcba.appspot.com/files/policy/TermsOfUse.json',
    refund: 'https://console.firebase.google.com/project/tazzo-techcba/storage/tazzo-techcba.appspot.com/files/policy/RefundPolicy.json',
  };

  useEffect(() => {
    const fetchPolicy = async () => {
      const url = policyUrls[route.params.policy];
      if (!url) return;

      try {
        const response = await fetch(url);
        const data = await response.json();
        setPolicy(data);
      } catch (error) {
        console.error("Error fetching policy:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPolicy();
  }, [route.params.policy]);

  const renderSection = ({ item }) => (
    <View style={styles.sectionContainer}>
      <Text style={[styles.sectionTitle, { color: colors.primary }]}>{item.title}</Text>
      <Text style={[styles.sectionContent, { color: colors.text }]}>{item.content}</Text>
    </View>
  );

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  if (!policy) {
    return <Text style={[styles.errorText, { color: colors.text }]}>Failed to load policy data.</Text>;
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Text style={[styles.policyTitle, { color: colors.primary }]}>{policy.title}</Text>
      <FlatList
        data={policy.content}
        renderItem={renderSection}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default PolicyScreen;