import { StyleSheet, StatusBar, Platform } from 'react-native';

export default StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    marginTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20, // Offset for iOS
    paddingBottom:80,
    alignSelf:'center',
    maxWidth: 600,
    width: '100%',
  },
  offerContainer: {
    padding: 20,
    borderRadius: 10,
    marginVertical: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 3
  },
  offerName: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  offerDetails: {
    fontSize: 16,
    marginVertical: 2,
    fontStyle: 'italic',
  },
  offerPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
    width: '100%',
    justifyContent: 'space-between',
    borderWidth:StyleSheet.hairlineWidth,
    borderRadius:10,
    padding:10
  },
  userInfoContainer: {
    flex: 1,
    paddingRight: 10,
  },
  userInfoText: {
    fontSize: 16,
    fontWeight: '600',
  },
  userInfoSubtext: {
    fontSize: 14,
  },
  authContainer: {
    alignItems: 'center',
    marginVertical: 20,
  },
  formContainer: {
    width: '100%',
    marginVertical: 10,
    alignItems:'center'
  },
  input: {
    borderRadius: 8,
    padding: 15,
    fontSize: 16,
    marginVertical: 8,
    width: '100%',
    maxWidth:320,
  },
  divider:{
    alignSelf:'center'
  },
  dividerText:{},
  loginButton: {
    paddingVertical: 10,
    borderRadius: 8,
    alignItems: 'center',
    width: 160,
  },
  loginText: {
    fontSize:16,
    fontWeight: 'bold',
    textAlign:'center'
  },
  socialLoginContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 15,
  },
  payUsingText:{
    textAlign:'center'
  },
  paymentButton: {
    paddingVertical: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginVertical: 8,
    width: '100%',
    maxWidth:320,
    alignSelf:'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  paymentButtonText: {
    marginHorizontal:10,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign:'center'
  },
  footerContainer: {
    alignItems: 'center',
  },
  loading: {
    marginTop: 120,
  },
});