import { StyleSheet } from "react-native";

export default StyleSheet.create({
  button:{
    marginLeft:10,
    alignItems:'center',
    justifyContent:'center',
    height:48,
    width:48,
    borderRadius:24,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation:2,
  },
  buttonIconContainer:{ 
    alignItems: 'center', 
    justifyContent: 'center' 
  },
  buttonIcon:{}
})