import { StyleSheet, Platform, StatusBar } from 'react-native';

export default StyleSheet.create({
  container: {
    padding: 16,
    paddingTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 12,
    textAlign: 'center',
  },
  itemContainer: {
    width: '90%',
    maxWidth:400,
    padding: 20,
    borderRadius: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 6,
    elevation: 5,
    marginVertical: 15,
    alignItems: 'center',
    alignSelf:'center'
  },
  itemIcon: {
    marginBottom: 15,
  },
  itemDetails: {
    width: '100%',
    marginTop: 10,
  },
  detailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
  },
  detailIcon: {
    marginRight: 10,
  },
  itemText: {
    fontSize: 16,
    fontWeight: '500',
  },
  icon: {
    marginVertical: 15,
    alignSelf:'center'
  },
  warningText: {
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 10,
    paddingHorizontal: 20,
    lineHeight: 22,
    maxWidth:320,
    alignSelf:'center'
  },
  button: {
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 10,
    alignItems: 'center',
    marginVertical: 20,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
    alignSelf:'center'
  },
  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf:'center'
  },
  errorText: {
    fontSize: 16,
    textAlign: 'center',
    color: 'red',
    marginVertical: 20,
  },
});