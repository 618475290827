import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Modal, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createTransactionStart, resetTransactionState } from './reducers';
import styles from './styles';
import { useTheme } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';

const CreateTransactionModal = ({ 
  visible, 
  onClose, 
  user,
  companyId, 
  productId, 
  offeringId, 
  selectedPaymentMethod 
}) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const transactionState = useSelector((state) => state.transaction);

  useEffect(() => {
    if (visible) {
      if (selectedPaymentMethod && user) {
        dispatch(createTransactionStart({ user, companyId, productId, offeringId, selectedPaymentMethod }));
      }
    }
  }, [visible, companyId, productId, offeringId, selectedPaymentMethod, dispatch]);

  useEffect(() => {
    if (transactionState.success) {
      onClose(transactionState.success); // Close modal on success
      dispatch(resetTransactionState());
    }
  }, [transactionState.success, onClose, dispatch]);

  const handleClose = () => {
    onClose(null);
    dispatch(resetTransactionState());
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={handleClose}
    >
      <View style={styles.modalOverlay}>
        <View style={[styles.modalContainer, { backgroundColor: colors.card }]}>
          {transactionState.loading ? (
            <>
              <ActivityIndicator size="large" color={colors.text} style={styles.loading} />
              <Text style={[styles.modalText, { color: colors.subText }]}>Creating</Text>
            </>
          ) : transactionState.error ? (
            <>
              <FontAwesome name="exclamation-circle" size={70} color={colors.notification} style={styles.errorIcon} />
              <Text style={[styles.errorText, { color: colors.notification }]}>Transaction could not be created</Text>
              <Text style={[styles.errorMessage, { color: colors.subText }]}>
                There was an issue processing your request. Please try again.
              </Text>
            </>
          ) : (
            <>
              <ActivityIndicator size="large" color={colors.text} style={styles.loading} />
              <Text style={[styles.modalText, { color: colors.subText }]}>Fetching</Text>
            </>
          )}

          <TouchableOpacity style={styles.cancelButton} onPress={handleClose}>
            <FontAwesome name="times" size={18} color={colors.text} />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default CreateTransactionModal;