import React from 'react';
import { View, Text, TouchableOpacity, Image, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';
import { FontAwesome } from '@expo/vector-icons';
import LogoHeader from '../../components/LogoHeader';
import EditUserPhoto from '../../components/EditUserPhoto';

const ProfileScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const user = useSelector((state) => state.app.user);
  const displayName = user?.auth.displayName || 'User Name';
  const displayPhoto = user?.auth.photoURL || '';
  const email = user?.auth.email || 'user@example.com';

  const profileData = [
    // { type: 'header' },
    { type: 'photo', displayPhoto },
    { type: 'name', displayName },
    { type: 'email', email },
  ];

  const renderItem = ({ item }) => {
    switch (item.type) {
      case 'header':
        return <LogoHeader />;
      case 'photo':
        return <EditUserPhoto/>;
      case 'name':
        return (
          <View style={styles.infoContainer}>
            <Text style={[styles.label, { color: colors.text }]}>Name</Text>
            <View style={styles.row}>
              <Text style={[styles.infoText, { color: colors.text }]}>{item.displayName}</Text>
              <TouchableOpacity style={styles.editIcon}>
                <FontAwesome name="edit" size={18} color={colors.primary} />
              </TouchableOpacity>
            </View>
          </View>
        );
      case 'email':
        return (
          <View style={styles.infoContainer}>
            <Text style={[styles.label, { color: colors.text }]}>Email</Text>
            <Text style={[styles.infoText, { color: colors.subText }]}>{item.email}</Text>
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <FlatList
      data={profileData}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
      contentContainerStyle={[styles.container, { backgroundColor: colors.background }]}
    />
  );
};

export default ProfileScreen;