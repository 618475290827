import { ofType } from 'redux-observable';
import { fromEventPattern } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Dimensions } from 'react-native';
import { subscribeDimensions, updateDimensions } from '../reducers/dimensionsSlice';

const dimensionsEpic = (action$) =>
  action$.pipe(
    tap(console.log),
    ofType(subscribeDimensions.type),
    mergeMap(() =>
      fromEventPattern(
        (handler) => Dimensions.addEventListener('change', handler),
        (_, unsubscribe) => Dimensions.removeEventListener('change', unsubscribe)
      ).pipe(
        map(({ window }) => updateDimensions({ width: window.width, height: window.height }))
      )
    )
  );

export default dimensionsEpic;