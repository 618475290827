import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 16,
    marginVertical: 8,
    borderRadius: 8,
    elevation: 2, // Adds a shadow effect on Android
    shadowColor: '#000', // Adds a shadow effect on iOS
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  cardContent: {
    flex: 1,
    marginRight: 16,
  },
  addressLine: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 4,
  },
  location: {
    fontSize: 14,
    marginBottom: 2,
  },
  country: {
    fontSize: 14,
    marginBottom: 8,
  },
  phone: {
    fontSize: 14,
    fontWeight: '400',
    marginTop: 8,
  },
  cardActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    padding: 8,
    height:36,
    width:36,
    borderRadius: 8,
    alignItems:'center',
    justifyContent:'center'
  },
  icon: {
    fontSize: 18,
  },
});