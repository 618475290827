import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    marginTop: 8,
    width:'100%',
    alignSelf: 'center'
  },
  statsContainer: {
    width: '100%',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    paddingVertical:8,
    paddingHorizontal:16,
    borderRadius:10,
    borderWidth:1,
  },
  statsCoinsInfo:{
    flexDirection:'row'
  },
  statCoinsItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'center',
    marginRight:20,
    borderRadius:24,
    height:32
  },
  statCoinIcon:{
    marginRight:6
  },
  statCoinsInfo:{},
  statCoinsInfoText:{},
  statValue: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  surveyButton: {
    paddingHorizontal: 8,
    borderRadius: 24,
    height:32,
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center'
  },
  surveyButtonText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  surveyArrowIcon:{
    marginLeft:4
  }
});