import { DefaultTheme, DarkTheme } from '@react-navigation/native';

export const Light = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#000',
    secondary: '#888',
    background: '#FFFFFF',
    text: '#000000',
    subText: '#666666', // Light subtext color
    card: '#F5F5F5',
    border: '#CCCCCC',
    brand: '#FF6347',
  },
};

export const Dark = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    primary: '#FFF',
    secondary: '#aaa',
    background: '#121212',
    text: '#FFFFFF',
    subText: '#AAAAAA', // Dark subtext color
    card: '#1F1F1F',
    border: '#333333',
    brand: '#FF6347',
  },
};