import React from 'react';
import { View, Text, TouchableOpacity, Alert } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { FontAwesome } from '@expo/vector-icons';
import styles from './styles';
import { useNavigation } from '@react-navigation/native';

const PolicyLinks = ({ style }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const { colors } = theme;

  const companyName = 'TechCBA Solutions Private Limited'
  const links = [
    { title: 'Privacy Policy', icon: 'lock', key: 'privacy' },
    { title: 'Terms of Service', icon: 'file-text-o', key: 'terms' },
    { title: 'Refund Policy', icon: 'credit-card', key: 'refund' },
  ];

  const handleLinkPress = (linkType) => {
    navigation.navigate('Policy', {policy:linkType});
  };

  const renderLink = (link, index) => (
    <TouchableOpacity
      key={link.key}
      onPress={() => handleLinkPress(link.key)}
      style={styles.policyLink}
    >
      <FontAwesome name={link.icon} size={14} color={colors.subText} style={styles.icon} />
      <Text style={[styles.linkText, { color: colors.subText }]}>{link.title}</Text>
      {index < links.length - 1 && <Text style={[styles.separator, { color: colors.subText }]}> | </Text>}
    </TouchableOpacity>
  );

  return (
    <View style={[styles.policyContainer, { borderBottomColor: colors.border }, style]}>
      <View style={styles.linksRow}>
        {links.map(renderLink)}
      </View>

      {/* Divider line */}
      <View style={[styles.dividerLine, { backgroundColor: colors.border }]} />

      {/* Company name below the divider */}
      <Text style={[styles.companyName, { color: colors.subText }]}>{companyName}</Text>
    </View>
  );
};

export default PolicyLinks;