import { StyleSheet, Platform, StatusBar } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer:{
    flexDirection:'row', 
    maxWidth:1000, 
    width:'100%', 
    alignSelf:'center',
    alignItems:'center'
  },
  contentContainer: {
    marginTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20, // Offset for iOS
    paddingBottom:80
  },
});