import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  productMedia: {
    borderRadius: 10,
    marginBottom: 15,
    overflow: 'hidden', // Ensures rounded corners
    borderWidth: 1,
    alignSelf: 'center',
    justifyContent: 'center',
  },
});