import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator, Alert, Text, TouchableOpacity, Modal, TextInput, ScrollView } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { db } from '../../services/firebase'; // Firebase setup
import styles from './styles'; // AddressesScreen-specific styles
import AddressCard from '../../components/AddressCard'; // Reusable AddressCard component
import { FontAwesome } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

const AddressesScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const user = useSelector(state=>state.app.user);

  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        setLoading(true);
        const addressesSnapshot = await db.collection(`/users/${user.uid}/addresses`).get();
        const addressesData = addressesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAddresses(addressesData);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        Alert.alert('Error', 'Failed to load addresses.');
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleEditAddress = (address) => {
    setCurrentAddress(address);
    setIsEditing(true);
    setModalVisible(true);
  };

  const handleAddAddress = () => {
    setCurrentAddress({
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      phone: '',
    });
    setIsEditing(false);
    setModalVisible(true);
  };

  const saveAddress = async () => {
    if (!currentAddress.addressLine1 || !currentAddress.city || !currentAddress.phone) {
      Alert.alert('Error', 'Please fill all mandatory fields.');
      return;
    }

    try {
      if (isEditing) {
        // Update existing address
        await db.collection(`/users/${user.uid}/addresses`).doc(currentAddress.id).update(currentAddress);
        setAddresses((prev) =>
          prev.map((addr) => (addr.id === currentAddress.id ? currentAddress : addr))
        );
      } else {
        // Add new address
        const newDocRef = await db.collection(`/users/${user.uid}/addresses`).add(currentAddress);
        setAddresses((prev) => [...prev, { id: newDocRef.id, ...currentAddress }]);
      }
      setModalVisible(false);
    } catch (error) {
      console.error('Error saving address:', error);
      Alert.alert('Error', 'Failed to save address.');
    }
  };

  const deleteAddress = async (addressId) => {
    try {
      await db.collection(`/users/${user.uid}/addresses`).doc(addressId).delete();
      setAddresses((prev) => prev.filter((addr) => addr.id !== addressId));
    } catch (error) {
      console.error('Error deleting address:', error);
      Alert.alert('Error', 'Failed to delete address.');
    }
  };

  const renderAddressItem = ({ item }) => (
    <AddressCard
      address={item}
      onEdit={() => handleEditAddress(item)}
      onDelete={() => deleteAddress(item.id)}
    />
  );

  if (loading) {
    return (
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      {addresses.length === 0 ? (
        <View style={styles.emptyContainer}>
          <FontAwesome name="map-marker" style={styles.emptyIcon(colors)} />
          <Text style={[styles.emptyText, { color: colors.text }]}>No addresses found.</Text>
          <TouchableOpacity
            style={[styles.addButton, { backgroundColor: colors.primary }]}
            onPress={handleAddAddress}
          >
            <Text style={[styles.addButtonText, { color: colors.background }]}>Add Address</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <FlatList
            data={addresses}
            renderItem={renderAddressItem}
            keyExtractor={(item) => item.id}
            contentContainerStyle={styles.listContainer}
          />
          <TouchableOpacity
            style={[styles.addButton, { backgroundColor: colors.brand }]}
            onPress={handleAddAddress}
          >
            <Text style={[styles.addButtonText, { color: colors.primary }]}>Add New Address</Text>
          </TouchableOpacity>
        </>
      )}

      {/* Address Modal */}
      <Modal visible={modalVisible} transparent={true} animationType="fade">
        <View style={styles.modalContainer}>
          <View style={[styles.modalContent, { backgroundColor: colors.card }]}>
            <ScrollView>
              <Text style={[styles.modalTitle, { color: colors.text }]}>
                {isEditing ? 'Edit Address' : 'Add Address'}
              </Text>
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="Address Line 1"
                maxLength={50}
                placeholderTextColor={colors.subText}
                value={currentAddress?.addressLine1}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, addressLine1: text }))
                }
              />
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="Address Line 2"
                maxLength={50}
                placeholderTextColor={colors.subText}
                value={currentAddress?.addressLine2}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, addressLine2: text }))
                }
              />
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="City"
                placeholderTextColor={colors.subText}
                value={currentAddress?.city}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, city: text }))
                }
              />
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="State"
                placeholderTextColor={colors.subText}
                value={currentAddress?.state}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, state: text }))
                }
              />
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="Country"
                placeholderTextColor={colors.subText}
                value={currentAddress?.country}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, country: text }))
                }
              />
              <TextInput
                style={[styles.input, { backgroundColor: colors.background, color: colors.text }]}
                placeholder="Phone Number"
                keyboardType="phone-pad"
                maxLength={15}
                placeholderTextColor={colors.subText}
                value={currentAddress?.phone}
                onChangeText={(text) =>
                  setCurrentAddress((prev) => ({ ...prev, phone: text }))
                }
              />
              <View style={styles.modalButtons}>
                <TouchableOpacity
                  style={[styles.cancelButton, { backgroundColor: colors.secondary }]}
                  onPress={() => setModalVisible(false)}
                >
                  <Text style={[styles.cancelButtonText, { color: colors.background }]}>
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.saveButton, { backgroundColor: colors.brand }]}
                  onPress={saveAddress}
                >
                  <Text style={[styles.saveButtonText, { color: colors.primary }]}>
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default AddressesScreen;