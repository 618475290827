import { StyleSheet } from "react-native";

export default StyleSheet.create({
  logoutButton:{
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height:36,
    width:36,
    borderRadius: 18,
  },
  logoutIcon:{
    marginLeft:4
  },
  logoutText:{
    fontSize: 12, 
    fontWeight: '600'
  }
})