import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  productInfoContainer: {
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center', // Centers the container
  },
  productTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  productDescription: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
  },
});