import React from "react";
import { View, Image, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import styles from "./styles";
import { useTheme } from "@react-navigation/native";

export default function({ appLogoRatio=1, companyLogo }) {
  const theme = useTheme();
  if (companyLogo) {
    return(
      <View style={styles.container}>
        <Image style={[styles.logo, {width:appLogoRatio*60}]} source={require('../../assets/logo.jpg')}></Image>
        <FontAwesome name="times" size={24} color={theme.colors.text} style={styles.cross} />
        <Image style={[styles.logo, {width:60*companyLogo?.aspectRatio}]} source={companyLogo?.source ? {uri: companyLogo.source} : require('../../assets/logo.jpg')}></Image>
      </View>
    )
  } else {
    return(
      <View style={styles.container}>
        <Image style={[styles.logo]} source={require('../../assets/logo.jpg')}></Image>
      </View>
    )
  }
}