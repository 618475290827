import { StyleSheet, Platform, StatusBar } from 'react-native';

export default StyleSheet.create({
  container: {
    // paddingTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20,
    paddingBottom: 20,
    paddingHorizontal:10,
    maxWidth:400,
    width:'100%',
    alignSelf:'center'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  transactionCard: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginVertical: 10,
    borderRadius: 12,
    borderWidth: 1,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 4,
  },
  transactionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  transactionTitle: {
    fontSize: 14,
    fontWeight: '700',
  },
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: 'rgba(0, 123, 255, 0.1)', // Subtle background for active status
  },
  statusIcon: {
    marginRight: 4,
  },
  transactionStatus: {
    fontSize: 14,
    fontWeight: '600',
    color: '#007BFF', // Use primary color if it matches the theme
    textTransform: 'capitalize',
  },
  transactionDetail: {
    fontSize: 14,
    marginVertical: 2,
  },
  transactionAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2ECC71', // Use a color that indicates amount/payment
    marginVertical: 6,
  },
  transactionDate: {
    fontSize: 12,
    textAlign: 'right',
    color: '#888', // Soft, muted color for dates
    marginTop: 10,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyIcon: {
    fontSize: 100,
    marginBottom: 20,
    opacity: 0.5,
  },
  emptyText: {
    fontSize: 18,
    textAlign: 'center',
    opacity: 0.8,
  },
});