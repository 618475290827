import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { FontAwesome } from '@expo/vector-icons';
import styles from './styles';

const OrderCard = ({ order }) => {
  const { theme } = useTheme();
  const { colors } = theme;

  const handleViewTransaction = () => {
    if (order.transactionId) {
      // Navigate to transaction details or perform any required action
      console.log('Transaction ID:', order.transactionId);
    } else {
      Alert.alert('Info', 'Transaction details not available.');
    }
  };

  return (
    <View style={[styles.card, { backgroundColor: colors.card }]}>
      <View style={styles.cardContent}>
        <Text style={[styles.orderTitle, { color: colors.text }]}>
          {order.productName || 'Product Name'}
        </Text>
        <Text style={[styles.orderDetails, { color: colors.subText }]}>
          Order ID: {order.id}
        </Text>
        <Text style={[styles.orderDetails, { color: colors.subText }]}>
          Ordered On: {order.createdAt || 'N/A'}
        </Text>
        <Text style={[styles.orderDetails, { color: colors.subText }]}>
          Price: ${order.price || 'N/A'}
        </Text>
      </View>
      <TouchableOpacity
        style={[styles.transactionButton, { backgroundColor: colors.primary }]}
        onPress={handleViewTransaction}
      >
        <FontAwesome name="file-text" size={16} color={colors.background} />
        <Text style={[styles.transactionButtonText, { color: colors.background }]}>
          View Transaction
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default OrderCard;