import { StyleSheet, Dimensions } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  logo: {
    resizeMode: 'contain',
    marginBottom: 20,
  },
  loadingIndicator: {
    marginTop: 20,
  },
});