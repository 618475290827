import React, { useState, useEffect, useRef } from 'react';
import { View, Text, FlatList, Image, Platform, TouchableOpacity, Alert } from 'react-native';
import { Video } from 'expo-av';
import { db } from '../../services/firebase';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const CategoryAdvert = ({ category, filterType }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const { colors } = theme;
  const [contentItems, setContentItems] = useState([]);
  const windowWidth = useSelector(state=>state.app.dimensions.width);
  const cardMaxWidth = 320;
  const viewMaxWidth = 1000;
  const numColumns = windowWidth > viewMaxWidth ? 3 : 1;
  const cardWidth = windowWidth > viewMaxWidth ? cardMaxWidth : windowWidth * 0.9;
  
  // Define videoRefs array to store references to each video
  const videoRefs = useRef([]);

  useEffect(() => {
    const fetchContent = async () => {
      let query = db.collectionGroup(filterType)
        .where('categories', 'array-contains', category)
        .where('advertPosition','>=',1)
        .orderBy('advertPosition', 'desc');

      const snapshot = await query.get();
      const data = snapshot.docs.map(doc => doc.data());
      setContentItems(data);
    };

    fetchContent();
  }, [category, filterType ]);

  const handleViewableItemsChanged = useRef(({ viewableItems }) => {
    viewableItems.forEach(({ item, isViewable, index }) => {
      const videoRef = videoRefs.current[index];
      if (item.media.type === 'video') {
        if (Platform.OS === 'web') {
          if (isViewable && videoRef) {
            videoRef.play(); // Play video on web
          } else if (videoRef) {
            videoRef.pause(); // Pause video on web
          }
        } else {
          if (isViewable) {
            videoRef?.playAsync(); // Play video on mobile
          } else {
            videoRef?.stopAsync(); // Stop video on mobile
          }
        }
      }
    });
  });

  const handleContentPress =(item) => {
    switch (item.access.screen) {
      case 'Company':
        navigation.navigate(item.access.screen, {companyId:item.access.id})
        break;
      case 'Product':
        navigation.navigate(item.access.screen, {productId:item.access.id, companyId:item.access.company})
        break;
    }
  }

  const renderContentItem = ({ item, index }) => (
    <TouchableOpacity 
      style={[styles.contentCard, { 
        backgroundColor: colors.card, width: cardWidth,
        marginTop:Platform.OS == 'web' ? 0 : 10,
        marginBottom:10,
        marginLeft:Platform.OS == 'web' ? 0 : 10,
        marginRight:Platform.OS == 'web' ? 20 : 10,
      }]}
      activeOpacity={0.7}
      onPress={()=>handleContentPress(item)}
    >
      {item.media.type === 'video' ? (
        Platform.OS === 'web' ? (
          <video
            ref={(ref) => (videoRefs.current[index] = ref)}
            src={item.media.source}
            style={styles.media}
            muted
            loop
            autoPlay
            playsInline
          />
        ) : (
          <Video
            ref={(ref) => (videoRefs.current[index] = ref)}
            source={{ uri: item.media.source }}
            style={styles.media}
            resizeMode="cover"
            isLooping={true}
            isMuted={true}
          />
        )
      ) : (
        <Image
          source={{ uri: item.media.source }}
          style={styles.media}
        />
      )}
      <Text style={[styles.contentTitle, { color: colors.text }]} numberOfLines={1}>
        {item.name}
      </Text>
      <Text style={[styles.contentDescription, { color: colors.text }]} numberOfLines={3}>
        {item.details || 'No description available.'}
      </Text>
      <View style={styles.adTextContainer}>
        <Text style={styles.adText}>Ad</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <FlatList
      data={contentItems}
      key={numColumns} // Force re-render on column change
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderContentItem}
      horizontal={Platform.OS == 'web' ? true : false}
      numColumns={Platform.OS == 'web' ? 1 : numColumns}
      contentContainerStyle={[styles.listContainer, { maxWidth: windowWidth > viewMaxWidth ? viewMaxWidth : '100%' }]}
      showsVerticalScrollIndicator={false}
      onViewableItemsChanged={handleViewableItemsChanged.current}
      viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
      style={{ alignSelf: 'center' }}
    />
  );
};

export default CategoryAdvert;