import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const ProductDetails = ({ name, details }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  const { width } = useWindowDimensions();

  return (
    <View
      style={[
        styles.productInfoContainer,
        {
          // backgroundColor: colors.card,
          // borderColor: colors.border,
          maxWidth: width > 600 ? 600 : width * 0.9, // Adapts to larger screens
        },
      ]}
    >
      <Text style={[styles.productTitle, { color: colors.text }]}>{name}</Text>
      <Text style={[styles.productDescription, { color: colors.subText || '#6c757d' }]}>
        {details || 'No details available.'}
      </Text>
    </View>
  );
};

export default ProductDetails;