import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth"
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA5aUH784pF8IGdW2O1uZqDgr3tv_gXezs",
  authDomain: "tazzo-techcba.firebaseapp.com",
  projectId: "tazzo-techcba",
  storageBucket: "tazzo-techcba.appspot.com",
  messagingSenderId: "927853830191",
  appId: "1:927853830191:web:4f3abd0393f210f884a6da",
  measurementId: "G-TG24MFP5SR"
};

export const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

export const fb = firebase
export const googleProvider = new firebase.auth.GoogleAuthProvider();