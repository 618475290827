import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator, StatusBar, Platform } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { useNavigation, useRoute } from '@react-navigation/native';
import { db } from '../../services/firebase';
import styles from './styles';

import LogoHeader from '../../components/LogoHeader';
import ProductMedia from '../../components/ProductMedia';
import ProductDetails from '../../components/ProductDetails';
import ProductOfferings from '../../components/ProductOfferings';

const ProductScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  const route = useRoute();
  const { companyId, productId } = route.params;

  const [product, setProduct] = useState(null);
  const [company, setCompany] = useState(null);
  const [offerings, setOfferings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!navigation.canGoBack()) {
      navigation.reset({
        index: 1,
        routes: [
          { name: 'Home' },
          { name: 'Product', params: { companyId, productId } },
        ],
      });
    }
  }, [navigation, companyId, productId]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productPath = `/companies/${companyId}/products/${productId}`;
        
        const productDoc = await db.doc(productPath).get();
        if (productDoc.exists) {
          setProduct(productDoc.data());
          
          const offeringsSnapshot = await db.collection(`${productPath}/offerings`).get();
          setOfferings(offeringsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
          
          const companyDoc = await db.doc(`/companies/${companyId}`).get();
          if (companyDoc.exists) {
            setCompany(companyDoc.data());
          }
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [companyId, productId]);

  const sections = [
    { key: 'logo', component: company && <LogoHeader companyLogo={company.logo} /> },
    { key: 'media', component: product?.media && <ProductMedia media={product.media} /> },
    { 
      key: 'details', 
      component: product && <ProductDetails name={product.name} details={product.details} /> 
    },
    { 
      key: 'offerings', 
      component: <ProductOfferings offerings={offerings} companyId={companyId} productId={productId} /> 
    },
  ];

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  if (!product) {
    return (
      <View style={styles.errorContainer}>
        <Text style={[styles.errorText, { color: colors.text }]}>Product not found</Text>
      </View>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <FlatList
        data={sections}
        renderItem={({ item }) => <View style={styles.sectionContainer}>{item.component}</View>}
        keyExtractor={(item) => item.key}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 20, alignItems: 'center', width: '100%' }}
      />
    </View>
  );
};

export default ProductScreen;