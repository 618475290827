import React, { useState, useEffect } from 'react';
import { TouchableOpacity, Animated, StyleSheet, View } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const ToggleThemeButton = ({ style, iconSize=24 }) => {
  const { theme, toggleTheme } = useTheme();
  const { colors } = theme;
  const [animation] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation, {
      toValue: theme.dark ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [theme.dark, animation]);

  const toggle = () => {
    toggleTheme(theme.dark ? 'light' : 'dark');
  };

  // Interpolating values for rotation
  const rotateInterpolate = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const animatedStyles = {
    transform: [{ rotate: rotateInterpolate }],
  };

  return (
    <TouchableOpacity activeOpacity={1} onPress={toggle} style={[styles.container, {backgroundColor: colors.card}, style]}>
      <Animated.View style={animatedStyles}>
        <FontAwesome
          name={theme.dark ? 'moon-o' : 'sun-o'}
          size={iconSize}
          color={theme.dark ? '#4B0082' : '#FFD700'} // Static color based on theme
        />
      </Animated.View>
    </TouchableOpacity>
  );
};

export default ToggleThemeButton;