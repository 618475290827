import React, { useState, useCallback } from 'react';
import { View, FlatList, StatusBar } from 'react-native';

import styles from './styles';

import FeaturedMedia from '../../components/FeaturedMedia';
import CategoryFilter from '../../components/CategoryFilter';
import ContentList from '../../components/ContentList';
import LogoHeader from '../../components/LogoHeader';
import AccountButton from '../../components/AccountButton';
import TotalCoinsInfo from '../../components/TotalCoinsInfo';
import UserStats from '../../components/UserStats';
import CategoryAdvert from '../../components/CategoryAdvert';

import { useTheme } from '../../context/ThemeContext';

const MainScreen = () => {
  // State for selected filters
  const { theme } = useTheme();
  const { colors } = theme;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterType, setFilterType] = useState('products');
  const [sortOrder, setSortOrder] = useState('purchaseCount');
  const [orderDirection, setOrderDirection] = useState('desc');

  // Array of sections for the FlatList
  const sections = [
    { key: 'logo', component: (
      <View style={styles.headerContainer}>
        <AccountButton />
        <LogoHeader />
        <TotalCoinsInfo />
      </View>
    )},
    { key: 'featured', component: <FeaturedMedia /> },
    { key: 'categories', component: (
      <CategoryFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        filterType={filterType}
        setFilterType={setFilterType}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
      />
    )},
    // { key: 'userStats2', component: (
    //   <UserStats externalStats={[
    //     { color:'#FFD700', value: 0 }
    //   ]} actionInfo='Refer'/>
    // )},
    { key: 'userStats1', component: (
      <UserStats  externalStats={[
        { color:'#FFD700', value: 0 },
        { color:'#C0C0C0', value: 0 }
      ]} actionInfo='User Tasks'/>
    )},
    { key: 'categoryAdvert', component: (
      <CategoryAdvert 
        category={selectedCategory} 
        filterType={filterType} 
      />
    )},
    { key: 'contentList', component: (
      <ContentList
        category={selectedCategory}
        filterType={filterType}
        sortOrder={sortOrder}
        orderDirection={orderDirection}
      />
    )},
  ];

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <FlatList
        data={sections}
        renderItem={({ item }) => <View style={styles.section}>{item.component}</View>}
        keyExtractor={(item) => item.key}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      />
    </View>
  );
};

export default MainScreen;