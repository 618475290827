import React from 'react';
import { View, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';
import { FontAwesome5 } from '@expo/vector-icons';

const UserStats = ({ externalStats, actionInfo='User Tasks' }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  
  const stats = externalStats ? externalStats : [
    // { color:'#FFD700', value: 0, actionInfo:'Redeemable' },
    // { color:'#C0C0C0', value: 0, actionInfo:'Non Redeemable' },
  ];

  const containerStyle = [
    styles.container,
    { maxWidth: width > 800 ? 480 : width * 0.9 },
  ];

  const handleTakeSurvey = () => {
    navigation.navigate('Survey');
  };

  return (
    <View style={containerStyle}>
      <View style={[styles.statsContainer, { borderColor: colors.brand }]}>
        <View style={[styles.statsCoinsInfo]}>
        {stats.map((stat, index) => (
          <View key={index}>
            <View style={[ styles.statCoinsItem ]}>
              <FontAwesome5
                name="coins"
                size={18}
                color={stat.color} // Distinctive gold/green for virtual coin look
                style={styles.statCoinIcon}
              />
              <Text style={[styles.statValue, { color: stat.color }]}>{stat.value}</Text>
            </View>
          </View>
        ))}
        </View>
        <TouchableOpacity
          style={[styles.surveyButton]}
          onPress={handleTakeSurvey}
        >
          <Text style={[styles.surveyButtonText, { color: colors.brand }]}>{actionInfo}</Text>
          <FontAwesome5
            name="arrow-right"
            size={14}
            color={colors.brand}
            style={styles.surveyArrowIcon}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default UserStats;