import React from 'react';
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';
import { useNavigation } from '@react-navigation/native';

const TotalCoinsInfo = ({ style, iconSize = 24, coinCount = 0 }) => {
  const { theme } = useTheme();
  const navigation = useNavigation();
  const { colors } = theme;

  return (
    <TouchableOpacity 
      activeOpacity={0.8} 
      style={[styles.container, { backgroundColor: colors.card }, style]}
      onPress={()=>navigation.navigate('Account')}
    >
      <View style={styles.coinsContainer}>
        <FontAwesome
          name="money"
          size={iconSize}
          color={colors.brand} // Distinctive gold/green for virtual coin look
          style={styles.coinIcon}
        />
        {/* <Text style={[styles.coinsText, { color: colors.text }]}>{coinCount}</Text> */}
      </View>
    </TouchableOpacity>
  );
};

export default TotalCoinsInfo;