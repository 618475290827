import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: null,
  profile: null,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    subscribeUserState: (state) => {
      // No payload; used to trigger the auth listener in the epic.
    },
    userStateUpdate: (state, action) => {
      state.auth = action.payload.auth;
      state.profile = action.payload.profile;
      state.error = null;
    },
    userStateError: (state, action) => {
      state.user = null;
      state.error = action.payload;
    },
  },
});

export const { subscribeUserState, userStateUpdate, userStateError } = userSlice.actions;

export default userSlice.reducer;