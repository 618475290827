import { StyleSheet, Dimensions, Platform } from 'react-native';


export default StyleSheet.create({
  flatList: {
    width: '100%',
  },
  scrollView: {
    width: '100%',
    flexGrow: 0, // Prevent the scroll view from expanding beyond its content
  },
  mediaContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  media: {
    borderRadius: 10,
    overflow: 'hidden',
    marginTop:10
  },
});