import React from 'react';
import { FlatList, TouchableOpacity, Text, View, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const ProductOfferings = ({ offerings, companyId, productId }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const { colors } = theme;
  const { width } = useWindowDimensions();

  const handleOfferingClick = (offering) => {
    navigation.navigate('Payment', {
      companyId,
      productId,
      offeringId: offering.id,
    });
  };

  const renderOfferingItem = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.offeringCard,
        {
          backgroundColor: colors.card,
          borderColor: colors.border,
          maxWidth: width > 800 ? 800 : width * 0.9, // Adapts to large screens
        },
      ]}
      onPress={() => handleOfferingClick(item)}
    >
      <View style={styles.offeringInfoContainer}>
        <Text style={[styles.offeringTitle, { color: colors.text }]}>{item.name}</Text>
        <Text style={[styles.offeringDetails, { color: colors.subText || '#6c757d' }]}>{item.details}</Text>
      </View>
      <View style={styles.offeringPriceContainer}>
        <Text style={[styles.offeringPrice]}>
          {item.price?.currency} {item.price?.amount}
        </Text>
        {item.price?.retail && (
          <Text style={[styles.offeringRetailPrice, { color: colors.secondary }]}>
            {item.price.currency} {item.price.retail}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <FlatList
      data={offerings}
      keyExtractor={(item) => item.id}
      renderItem={renderOfferingItem}
      contentContainerStyle={[styles.listContainer, { alignItems: 'center' }]}
    />
  );
};

export default ProductOfferings;