import { StyleSheet, Platform, StatusBar } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    // paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight || 20 : 0, // Offset for status bar on Android
    marginTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20, // Offset for iOS
    paddingBottom:80
  },
  productHeading: {
    fontWeight:'bold',
    alignSelf:'center',
    fontSize:21,
  }
});