import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList, Modal, Image, useWindowDimensions, Linking } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '../../context/ThemeContext';
import { db } from '../../services/firebase';
import styles from './styles';

const CategoryFilter = ({
  company,
  selectedCategory,
  setSelectedCategory,
  filterType,
  setFilterType,
  sortOrder,
  setSortOrder,
  orderDirection,
  setOrderDirection,
}) => {
  const { theme } = useTheme();
  const { colors } = theme;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const windowWidth = useWindowDimensions().width;
  const [selectedSubcategory, setSelectedSubcategory] = useState('All');

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      const categoryDocs = await db
        .collection(company ? `companies/${company.access.id}/categories` : 'categories')
        .orderBy('position', 'desc')
        .get();
      const categoryData = categoryDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const firstNonLinkCategory = categoryData.find((category) => category.type !== 'link');
      if (firstNonLinkCategory) {
        setSelectedCategory(firstNonLinkCategory.name);
        setActiveCategory(firstNonLinkCategory);
      }

      setCategories(categoryData);
    };
    fetchCategories();
  }, []);

  // Fetch subcategories for the active category
  useEffect(() => {
    if (activeCategory) {
      const fetchSubCategories = async () => {
        const subCategoryDocs = await db
          .collection(company ? `/companies/${company.access.id}/categories/${activeCategory.id}/subcategories` : `/categories/${activeCategory.id}/subcategories`)
          .orderBy('position', 'desc')
          .get();
        const subCategoryData = subCategoryDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSubCategories([
          { name: 'Filter', isFilterButton: true }, 
          { name: 'All', id: 'all' }, 
          ...subCategoryData
        ]);
      };
      fetchSubCategories();
    }
  }, [activeCategory]);

  const toggleModal = () => setModalVisible(!isModalVisible);

  const maxContainerWidth = windowWidth < 800 ? windowWidth * 0.9 : 800;

  const handleCategoryPress = (item) => {
    if (item.type === 'link') {
      Linking.openURL(item.url);
    } else {
      setActiveCategory(item);
      setSelectedCategory(item.name);
      setSelectedSubcategory('All')
    }
  };

  const handleSubCategoryPress = (subCategory) => {
    if (subCategory.name === 'All') {
      setSelectedCategory(activeCategory.name);
      setSelectedSubcategory(subCategory.name);
    } else {
      setSelectedCategory(`${activeCategory.name}-${subCategory.name}`);
      setSelectedSubcategory(subCategory.name);
    }
  };

  const renderCategoryItem = ({ item }) => {
    return (
      <TouchableOpacity
        style={[
          styles.categoryButton,
          { backgroundColor: activeCategory.name === item.name ? colors.notification : colors.card },
        ]}
        onPress={() => handleCategoryPress(item)}
      >
        {item.image?.type === 'icon' ? (
          <View style={styles.categoryIconContainer}>
            <FontAwesome
              name={item.image.source}
              size={21}
              color={activeCategory.name === item.name ? colors.text : colors.subText}
            />
          </View>
        ) : (
          <Image style={styles.customCategoryImage} source={{ uri: item.image?.source }} />
        )}
        {item.showName && (
          <Text
            style={[
              styles.categoryText,
              { color: activeCategory.name === item.name ? colors.text : colors.subText },
            ]}
          >
            {item.name}
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderSubCategoryItem = ({ item }) => {
    
    if (item.isFilterButton) {
      return (
        <TouchableOpacity
          onPress={toggleModal}
          style={[styles.filterIconContainer, { backgroundColor: colors.card }]}
        >
          <FontAwesome name="sliders" size={21} color={colors.text} />
        </TouchableOpacity>
      );
    }

    return (
    <TouchableOpacity
      style={[
        styles.subCategoryButton,
        {
          backgroundColor: selectedSubcategory == item.name ? colors.notification : colors.card,
        },
      ]}
      onPress={() => handleSubCategoryPress(item)}
    >
      <Text
        style={[
          styles.subCategoryText,
          { color: selectedSubcategory == item.name ? colors.text : colors.subText },
        ]}
      >
        {item.name}
      </Text>
    </TouchableOpacity>
  )};

  return (
    <View
      style={[
        styles.container,
        { maxWidth: maxContainerWidth, backgroundColor: colors.background },
        { 
          height: company ? 160 : 250,
          marginTop: company ? 0 : 10
        }
      ]}
    >
      {/* Category Section */}
      <FlatList
        data={categories}
        horizontal={company?true:false}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item) => item.id || item.name}
        renderItem={renderCategoryItem}
        contentContainerStyle={styles.categoryList}
        numColumns={company?1:3}
      />

      {/* Subcategory Section */}
      {subCategories.length > 0 && (
        <FlatList
          data={subCategories}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.id || item.name}
          renderItem={renderSubCategoryItem}
          contentContainerStyle={styles.subCategoryList}
        />
      )}

      {/* Modal for Filter Options */}
      <Modal
        transparent
        animationType="fade"
        visible={isModalVisible}
        onRequestClose={toggleModal}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPressOut={toggleModal}
        >
          <View style={[styles.modalContainer, { backgroundColor: colors.background }]}>
            <Text style={[styles.modalTitle, { color: colors.text }]}>Settings</Text>
            {!company && (
              <View style={styles.optionSection}>
                <FilterOption
                  label="Product"
                  selected={filterType === 'products'}
                  onPress={() => setFilterType('products')}
                  colors={colors}
                />
                <FilterOption
                  label="Company"
                  selected={filterType === 'companies'}
                  onPress={() => setFilterType('companies')}
                  colors={colors}
                />
              </View>
            )}
            <View style={styles.optionSection}>
              <FilterOption
                label="Price"
                selected={sortOrder === 'startingPrice'}
                onPress={() => setSortOrder('startingPrice')}
                colors={colors}
              />
              <FilterOption
                label="Latest"
                selected={sortOrder === 'datetime'}
                onPress={() => setSortOrder('datetime')}
                colors={colors}
              />
              <FilterOption
                label="Trending"
                selected={sortOrder === 'purchaseCount'}
                onPress={() => setSortOrder('purchaseCount')}
                colors={colors}
              />
            </View>
            <View style={styles.optionSection}>
              <FilterOption
                label="Low to High"
                selected={orderDirection === 'asc'}
                onPress={() => setOrderDirection('asc')}
                colors={colors}
              />
              <FilterOption
                label="High to Low"
                selected={orderDirection === 'desc'}
                onPress={() => setOrderDirection('desc')}
                colors={colors}
              />
            </View>
            <TouchableOpacity onPress={toggleModal} style={[styles.closeButton]}>
              <FontAwesome name="times" size={18} color={colors.text} />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};


// FilterOption Component to reuse for each filter option
const FilterOption = ({ label, selected, onPress, colors }) => (
  <TouchableOpacity onPress={onPress} style={[styles.option, { backgroundColor: selected ? colors.notification : colors.card }]}>
    <Text style={[styles.optionText, { color: selected ? colors.text : colors.border }]}>
      {label}
    </Text>
    {selected && <FontAwesome name="check" size={16} color={colors.text} style={styles.checkIcon} />}
  </TouchableOpacity>
);

export default CategoryFilter;