import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    marginTop:10,
    borderRadius:10,
    alignSelf: 'center',
    justifyContent:'center',
    alignItems: 'center',
  },
  categoryList: {
    justifyContent: 'center',
    alignItems:'center',
  },
  categoryButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 80,
    borderRadius: 10,
    marginHorizontal: 8,
    marginVertical:8,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation:3,
  },
  categoryIconContainer:{
    width:100,
    height:40,
    justifyContent:'center',
    alignItems:'center'
  },
  customCategoryImage: {
    width: 100,
    height: 40,
    resizeMode:'contain'
  },
  categoryText: {
    marginTop:4,
    fontSize: 12,
    textAlign: 'center',
  },
  subCategoryList: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  subCategoryButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 20,
    marginHorizontal: 6,
    borderRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 2,
  },
  subCategoryText: {
    fontSize: 14,
    fontWeight: '500',
  },
  filterIconContainer:{
    height:36,
    width:36,
    alignItems:'center',
    justifyContent:'center',
    borderRadius:18,
    marginRight:10,
    marginLeft:10,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation:3,
  },
  filterIcons:{},
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalContainer: {
    position: 'relative',
    minWidth:260,
    maxWidth:480,
    alignSelf:'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginHorizontal: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.5,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 4 },
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  option: {
    minWidth:200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 5,
    width: '100%',
    borderRadius: 10,
  },
  optionSection:{
    alignItems:'center',
    justifyContent:'center',
    padding:8,
    margin:8,
  },
  optionText: {
    fontSize: 16,
    fontWeight: '600',
  },
  checkIcon: {
    marginLeft: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  customCloseButton: {
    position: 'absolute',
    bottom: 30,
    alignSelf:'center',
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:'#fff'
  },
});