import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  mediaContainer: {
    marginTop: 15, // Margin at top and bottom
    paddingHorizontal: 10, // Padding inside container
    paddingVertical: 5,
  },
  media: {
    aspectRatio: 16 / 9, // Maintain a 16:9 aspect ratio
    borderRadius: 10,
    overflow: 'hidden', // Ensures rounded corners on web
    width: '100%', // Full width of the container
  },
});