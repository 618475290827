import React, { createContext, useContext, useState, useEffect } from 'react';
import { useColorScheme } from 'react-native';
import { Dark, Light } from '../theme';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const systemTheme = useColorScheme();
  
  const [theme, setTheme] = useState(systemTheme === 'dark' ? Dark : Light);

  useEffect(() => {
    if (systemTheme) {
      setTheme(systemTheme === 'dark' ? Dark : Light);
    }
  }, [systemTheme]);

  const toggleTheme = (themeType) => {
    setTheme(themeType === 'dark' ? Dark : Light);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);