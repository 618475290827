import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, FlatList, ActivityIndicator, Platform } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import * as Linking from 'expo-linking';
import { FontAwesome } from '@expo/vector-icons';
import { db } from '../../services/firebase';
import LogoHeader from '../../components/LogoHeader';
import styles from './styles';
import { useRoute } from '@react-navigation/native';

const ExternalGatewayScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const route = useRoute();
  const { transactionPath } = route.params;
  const [externalUrl, setExternalUrl] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const openExternalGateway = () => {
    if (Platform.OS === 'web') {
      window.open(externalUrl, '_blank');
    } else {
      Linking.openURL(externalUrl);
    }
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const transactionDoc = await db.doc(transactionPath).get();
        if (transactionDoc.exists) {
          setTransactionData(transactionDoc.data());
          setExternalUrl(transactionDoc.data().paymentMethod.link);
        } else {
          console.error('Transaction document not found');
        }
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [transactionPath]);

  if (loading) {
    return <ActivityIndicator size="large" color={colors.primary} style={styles.loading} />;
  }

  if (!transactionData) {
    return <Text style={[styles.errorText, { color: colors.text }]}>Transaction data not found</Text>;
  }

  const { company, product, offering } = transactionData;

  const data = [
    { id: 'logo', component: <LogoHeader /> },
    {
      id: 'details',
      component: (
        <View style={[styles.itemContainer, { backgroundColor: colors.card }]}>
          <FontAwesome name="shopping-bag" size={50} color={colors.primary} style={styles.itemIcon} />
          <View style={styles.itemDetails}>
            <View style={styles.detailRow}>
              <FontAwesome name="tag" size={18} color={colors.primary} style={styles.detailIcon} />
              <Text style={[styles.itemText, { color: colors.text }]}>Offering: {offering?.name || 'N/A'}</Text>
            </View>
            <View style={styles.detailRow}>
              <FontAwesome name="archive" size={18} color={colors.primary} style={styles.detailIcon} />
              <Text style={[styles.itemText, { color: colors.text }]}>Product: {product?.name || 'N/A'}</Text>
            </View>
            <View style={styles.detailRow}>
              <FontAwesome name="building" size={18} color={colors.primary} style={styles.detailIcon} />
              <Text style={[styles.itemText, { color: colors.text }]}>Company: {company?.name || 'N/A'}</Text>
            </View>
            <View style={styles.detailRow}>
              <FontAwesome name="dollar" size={18} color={colors.primary} style={styles.detailIcon} />
              <Text style={[styles.itemText, { color: colors.text }]}>Amount: ${offering?.price?.amount || 'N/A'}</Text>
            </View>
          </View>
        </View>
      ),
    },
    {
      id: 'header',
      component: (
        <>
          <FontAwesome name="external-link" size={60} color={colors.primary} style={styles.icon} />
          <Text style={[styles.header, { color: colors.text }]}>Proceed to Pay</Text>
          <Text style={[styles.warningText, { color: colors.secondary }]}>
            You are being redirected to a secure external payment provider outside the app.
          </Text>
        </>
      ),
    },
    {
      id: 'button',
      component: (
        <TouchableOpacity
          style={[styles.button, { backgroundColor: colors.primary }]}
          onPress={openExternalGateway}
        >
          <Text style={[styles.buttonText, { color: colors.background }]}>Go to External Gateway</Text>
        </TouchableOpacity>
      ),
    },
  ];

  const renderItem = ({ item }) => <View>{item.component}</View>;

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      contentContainerStyle={[styles.container, { backgroundColor: colors.background }]}
    />
  );
};

export default ExternalGatewayScreen;