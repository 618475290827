import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { createTransactionStart, createTransactionSuccess, createTransactionError } from '../reducers';
import { ajax } from 'rxjs/ajax';

export const startCreateTransaction = (action$) =>
  action$.pipe(
    ofType(createTransactionStart.type),
    mergeMap((action) =>
      ajax.post('https://api-zvjbdkdazq-el.a.run.app/createTransaction', action.payload).pipe(
        map((response) => {
          if (response.status === 200) {
            return createTransactionSuccess(response.response); // Assuming response contains the necessary info
          }
          throw new Error("Transaction creation failed");
        }),
        catchError((error) => of(createTransactionError(error.message)))
      )
    )
  );


export default startCreateTransaction