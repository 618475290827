import { createSlice } from '@reduxjs/toolkit';
import { Dimensions } from 'react-native';

const initialState = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {
    subscribeDimensions: (state) => {
      // No payload, triggers the epic listener
    },
    updateDimensions: (state, action) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },
  },
});

export const { subscribeDimensions, updateDimensions } = dimensionsSlice.actions;

export default dimensionsSlice.reducer;