import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image, Alert, ActivityIndicator } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '../../context/ThemeContext';
import { auth, storage } from '../../services/firebase'; // Ensure your storage is configured
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import styles from './styles';
import { useSelector } from 'react-redux';

const EditUserPhoto = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const user = useSelector(state=>state.app.user);
  const [currentPhoto, setCurrentPhoto] = useState(user?.auth?.photoUrl || user?.profile?.photoUrl);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImagePick = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!permissionResult.granted) {
      Alert.alert('Permission required', 'Permission to access the camera roll is required!');
      return;
    }
    
    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
    });

    if (!pickerResult.canceled) {
      uploadPhoto(pickerResult.uri);
    }
  };

  const uploadPhoto = async (uri) => {
    try {
      setUploading(true);
      const response = await fetch(uri);
      const blob = await response.blob();
      const storageRef = ref(storage, `profilePhotos/${auth.currentUser.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Error uploading photo:', error);
          Alert.alert('Error', 'Photo upload failed.');
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setCurrentPhoto(downloadURL);
          await updateProfile(auth.currentUser, { photoURL: downloadURL });
          setUploading(false);
          setUploadProgress(0);
          Alert.alert('Success', 'Profile photo updated successfully!');
        }
      );
    } catch (error) {
      console.error('Error handling photo upload:', error);
      Alert.alert('Error', 'Failed to upload photo.');
      setUploading(false);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleImagePick} style={styles.imageContainer}>
        {currentPhoto ? (
          <Image source={{ uri: currentPhoto }} style={styles.image} />
        ) : (
          <FontAwesome name="user-circle" size={100} color={colors.primary} />
        )}
        {uploading && (
          <View style={styles.overlay}>
            <Text style={styles.uploadProgressText}>{uploadProgress}%</Text>
            <ActivityIndicator color={colors.background} />
          </View>
        )}
        <View style={styles.editIconContainer}>
          <FontAwesome name="camera" size={20} color={colors.background} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default EditUserPhoto;