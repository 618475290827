import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, Keyboard, Platform, KeyboardAvoidingView, TouchableWithoutFeedback, useColorScheme } from 'react-native';
import { auth } from '../../services/firebase';
import styles from './styles';
import { Light, Dark } from '../../theme';

const SignUpScreen = ({ navigation }) => {
  const colorScheme = useColorScheme();
  const theme = colorScheme === 'dark' ? Dark : Light;

  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // Regular expressions for validation
  const displayNameRegex = /^[a-zA-Z ]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleSignUp = async () => {
    if (!displayNameRegex.test(displayName)) {
      Alert.alert("Invalid Name", "Name should only contain letters and spaces.");
      return;
    }
    if (!emailRegex.test(email)) {
      Alert.alert("Invalid Email", "Please enter a valid email address.");
      return;
    }
    if (!passwordRegex.test(password)) {
      Alert.alert(
        "Invalid Password",
        "Password must be at least 8 characters long and include uppercase, lowercase, a number, and a special character."
      );
      return;
    }
    if (password !== confirmPassword) {
      Alert.alert("Password Mismatch", "Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      await user.updateProfile({ displayName });
      Alert.alert("Sign Up Successful", "Your account has been created!");
      navigation.navigate("Home");
    } catch (error) {
      Alert.alert("Sign Up Failed", error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderFormContent = () => (
    <KeyboardAvoidingView
      style={[styles.container, { backgroundColor: theme.colors.background }]}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <View style={[styles.formContainer, { backgroundColor: theme.colors.card }]}>
        <Text style={[styles.title, { color: theme.colors.text }]}>Create an Account</Text>

        <TextInput
          placeholder="Display Name"
          placeholderTextColor={theme.colors.border}
          style={[
            styles.input,
            { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
          ]}
          value={displayName}
          onChangeText={setDisplayName}
          keyboardType="default"
          autoCapitalize="words"
        />

        <TextInput
          placeholder="Email"
          placeholderTextColor={theme.colors.border}
          style={[
            styles.input,
            { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
          ]}
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
          autoCapitalize="none"
        />

        <TextInput
          placeholder="Password"
          placeholderTextColor={theme.colors.border}
          style={[
            styles.input,
            { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
          ]}
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          autoCapitalize="none"
        />

        <TextInput
          placeholder="Confirm Password"
          placeholderTextColor={theme.colors.border}
          style={[
            styles.input,
            { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
          ]}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
          secureTextEntry
          autoCapitalize="none"
        />

        <TouchableOpacity
          style={[styles.button, { backgroundColor: theme.colors.notification }]}
          onPress={handleSignUp}
          disabled={loading}
        >
          <Text style={[styles.buttonText, { color: theme.colors.text }]}>{loading ? "Signing up..." : "Sign Up"}</Text>
        </TouchableOpacity>

        <Text style={[styles.signupText, { color: theme.colors.text }]}>
          Already have an account?{' '}
          <Text style={[styles.signupLink, { color: theme.colors.notification }]} onPress={() => navigation.navigate("Login")}>
            Log In
          </Text>
        </Text>

        {/* Terms of Use and Privacy Policy */}
        <Text style={[styles.termsText, { color: theme.colors.text }]}>
          By signing up, you agree to our{' '}
          <Text style={[styles.link, { color: theme.colors.notification }]} onPress={() => Alert.alert("Terms of Use", "Terms content here")}>
            Terms of Use
          </Text>{' '}
          and{' '}
          <Text style={[styles.link, { color: theme.colors.notification }]} onPress={() => Alert.alert("Privacy Policy", "Privacy content here")}>
            Privacy Policy
          </Text>.
        </Text>
      </View>
    </KeyboardAvoidingView>
  );

  return Platform.OS === 'web' ? renderFormContent() : (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>{renderFormContent()}</TouchableWithoutFeedback>
  );
};

export default SignUpScreen;