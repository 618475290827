import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    height:36,
    width:36,
    borderRadius: 30,
    borderRadius:20,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation:2,
  },
});