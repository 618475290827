import React from 'react';
import { View, Image, Platform, Dimensions } from 'react-native';
import { Video } from 'expo-av';
import { useSelector } from 'react-redux';
import styles from './styles';

const FeaturedMedia = ({ company }) => {
  const windowWidth = useSelector((state) => state.app.dimensions.width);
  const maxWidth = 800; // Set max width for larger screens
  const mediaWidth = windowWidth > maxWidth ? maxWidth : windowWidth; // Responsive width

  return (
    <View style={[styles.mediaContainer, { width: mediaWidth, alignSelf: windowWidth > maxWidth ? 'center' : 'stretch' }]}>
      {company.media.type === 'video' ? (
        Platform.OS === 'web' ? (
          <video
            src={company.media.source}
            style={{ ...styles.media, width: '100%' }} // Spread style for web compatibility
            muted
            autoPlay
            loop
            playsInline
          />
        ) : (
          <Video
            source={{ uri: company.media.source }}
            style={styles.media}
            resizeMode="cover"
            isLooping
            isMuted
            shouldPlay
          />
        )
      ) : (
        <Image
          source={{ uri: company.media.source }}
          style={styles.media}
          resizeMode="cover"
        />
      )}
    </View>
  );
};

export default FeaturedMedia;