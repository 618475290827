import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  offeringCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 3,
    marginBottom: 20,
    minWidth: 320,
  },
  offeringInfoContainer: {
    flex: 1,
    marginRight: 10,
  },
  offeringTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  offeringDetails: {
    fontSize: 14,
    color: '#6c757d', // Optional for muted text
  },
  offeringPriceContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  offeringPrice: {
    fontSize: 16,
    fontWeight: '500',
    color: '#28a745', // Optional for green price
  },
  offeringRetailPrice: {
    fontSize: 14,
    color: '#aaa',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
});