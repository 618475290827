import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, TouchableOpacity, ActivityIndicator, Alert, SafeAreaView } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { useNavigation } from '@react-navigation/native';
import { db } from '../../services/firebase';
import { useSelector } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import styles from './styles';
import LogoHeader from '../../components/LogoHeader';

const TransactionsScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  const user = useSelector((state) => state.app.user);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsSnapshot = await db
          .collection('users')
          .doc(user?.auth.uid)
          .collection('transactions')
          .orderBy('lastUpdated', 'desc')
          .get();

        const transactionsData = transactionsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            path: doc.ref.path,
            isActive:
              data.status === 'pay now' &&
              new Date() - new Date(data.createdAt) < 5 * 60 * 1000, // Active for 5 mins
          };
        });

        setTransactions(transactionsData);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        Alert.alert('Error', 'Failed to load transactions.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const handleTransactionPress = (transaction) => {
    if (transaction.paymentMethod && transaction.paymentMethod.screen) {
      navigation.navigate(transaction.paymentMethod.screen, { 
        transactionPath: transaction.path 
      });
    } else {
      Alert.alert('Error', 'Payment method not available for this transaction.');
    }
  };

  const renderTransactionItem = ({ item }) => {
    const statusText = item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : 'Unknown';

    return (
      <TouchableOpacity
        onPress={() => handleTransactionPress(item)}
        style={[
          styles.transactionCard,
          {
            backgroundColor: colors.card,
            borderColor: item.isActive ? colors.primary : colors.border,
          },
        ]}
        disabled={item.status === 'expired'}
      >
        <View style={styles.transactionHeader}>
          <Text style={[styles.transactionTitle, { color: colors.text }]}>{item.product?.name || 'N/A'}</Text>
          <View style={styles.statusContainer}>
            <FontAwesome
              name={item.isActive ? 'credit-card' : 'clock-o'}
              size={16}
              color={item.isActive ? colors.primary : colors.subText}
              style={styles.statusIcon}
            />
            <Text style={[styles.transactionStatus, { color: item.isActive ? colors.primary : colors.subText }]}>
              {item.isActive ? 'Pay Now' : statusText}
            </Text>
          </View>
        </View>
        <Text style={[styles.transactionDetail, { color: colors.subText }]}>{item.offering?.name || 'N/A'}</Text>
        <Text style={[styles.transactionDetail, { color: colors.subText }]}>{item.company?.name || 'N/A'}</Text>
        <Text style={[styles.transactionAmount, { color: colors.brand }]}>
          {item.offering?.price?.amount || 'N/A'} {item.offering?.price?.currency || ''}
        </Text>
        <Text style={[styles.transactionDate, { color: colors.secondary }]}>
          Last Updated: {new Date(item.lastUpdated).toLocaleString()}
        </Text>
      </TouchableOpacity>
    );
  };

  if (loading) {
    return <ActivityIndicator size="large" color={colors.primary} style={styles.loading} />;
  }

  if (!transactions.length) {
    return (
      <View style={[styles.emptyContainer, { backgroundColor: colors.background }]}>
        <FontAwesome name="credit-card" style={[styles.emptyIcon, { color: colors.text }]} />
        <Text style={[styles.emptyText, { color: colors.text }]}>No transactions found</Text>
      </View>
    );
  }

  return (
      <FlatList
        // ListHeaderComponent={<LogoHeader />}
        data={transactions}
        renderItem={renderTransactionItem}
        keyExtractor={(item) => item.id}
        contentContainerStyle={[styles.container, { backgroundColor: colors.background }]}
        showsVerticalScrollIndicator={false}
      />
  );
};

export default TransactionsScreen;