import React from 'react';
import { Provider } from 'react-redux';
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { ThemeProvider, useTheme } from './src/context/ThemeContext';
import store from './src/state';
import Screens from './src/screens';

export default function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

const linking = {
  prefixes: ['https://tazzo.io', 'tazzo://', 'http://localhost:19006'],
  config: {
    screens: {
      Company: 'companies/:companyId',
      Product: 'companies/:companyId/products/:productId',
      Payment: 'companies/:companyId/products/:productId/offerings/:offeringId',
    },
  },
};

function AppContent() {
  const { theme } = useTheme(); // Now inside ThemeProvider

  return (
    <Provider store={store}>
      <NavigationContainer theme={theme} linking={linking}>
          <Screens />
          <StatusBar style="auto" />
      </NavigationContainer>
    </Provider>
  );
}