import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    marginVertical: 10,
    alignItems: 'center',
    borderColor:'#aaa',
    borderBottomWidth:StyleSheet.hairlineWidth,
  },
  companyName: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  detailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailItem: {
    flex:1,
    marginHorizontal:5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
  },
  detailText: {
    fontSize: 14,
    margin: 10,
  },
  description: {
    fontSize: 16,
    textAlign: 'center',
    marginTop:10,
    marginBottom:25
  },
});