import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AppLoadingScreen from './AppLoadingScreen';
import LoginScreen from './LoginScreen';
import SignUpScreen from './SignUpScreen';
import HomeScreen from './HomeScreen';
import CompanyScreen from './CompanyScreen';
import ProductScreen from './ProductScreen';
import PaymentScreen from './PaymentScreen';
import PolicyScreen from './PolicyScreen';
import AccountScreen from './AccountScreen';
import TransactionsScreen from './TransactionsScreen';
import ExternalGatewayScreen from './ExternalGatewayScreen';
import EditProfileScreen from './EditProfileScreen';
import OrdersScreen from './OrdersScreen';

import AccountButton from '../components/AccountButton';
import TotalCoinsInfo from '../components/TotalCoinsInfo';
import WebPaymentScreen from './WebPaymentScreen';
import { useTheme } from '../context/ThemeContext';
import AddressesScreen from './AddressesScreen';

const Stack = createNativeStackNavigator();

// Define default screen options
const screenOptions = {
  headerShown: true,
  headerTitle: '', // Removes the title
  headerTransparent: true, // Makes the header background transparent
  headerStyle: {
    backgroundColor: 'transparent',
    elevation: 0, // Removes shadow on Android
    shadowOpacity: 0, // Removes shadow on iOS
  },
  headerBackTitleVisible: false
};

export default function Main() {
  const { theme } = useTheme(); // Get theme context
  const { colors } = theme; // Access colors from the theme

  return (
    <Stack.Navigator initialRouteName="AppLoading">
      <Stack.Screen 
        name="AppLoading"
        component={AppLoadingScreen} 
        options={{ headerShown: false }}
      />
      <Stack.Screen 
        name="Login"
        component={LoginScreen} 
        options={{
          ...screenOptions,
          headerLeft: null, // Hide back button for login
        }}
      />
      <Stack.Screen 
        name="SignUp"
        component={SignUpScreen} 
        options={screenOptions}
      />
      <Stack.Screen 
        name="Home"
        component={HomeScreen} 
        options={{
          ...screenOptions,
          headerShown:false,
          // Not needed, created it on homescreen
          // headerLeft: () => <AccountButton />, // Adds Account Button in the header
          // headerRight: () => <TotalCoinsInfo />, // Adds Theme Toggle button in the header,
        }}
      />
      <Stack.Screen 
        name="Company"
        component={CompanyScreen}
        options={screenOptions}
      />
      <Stack.Screen 
        name="Product"
        component={ProductScreen} 
        options={screenOptions}
      />
      <Stack.Screen 
        name="Payment"
        component={PaymentScreen} 
        options={screenOptions}
      />
      <Stack.Screen 
        name="Account"
        component={AccountScreen} 
        options={{
          ...screenOptions,
          headerShown:true,
          headerTitle:'Account',
          headerTransparent:false,
          headerStyle:{
            backgroundColor:colors.background
          }
        }}
      />
      <Stack.Screen 
        name="Policy"
        component={PolicyScreen} 
        options={{
          ...screenOptions,
          headerShown:true,
          headerTitle:'Policy',
          headerTransparent:false,
          headerStyle:{
            backgroundColor:colors.background
          }
        }}
      />
      <Stack.Screen 
        name="Transactions"
        component={TransactionsScreen} 
        options={{
          ...screenOptions,
          headerShown:true,
          headerTitle:'Transactions',
          headerTransparent:false,
          headerStyle:{
            backgroundColor:colors.background
          }
        }}
      />
      <Stack.Screen 
        name="Addresses"
        component={AddressesScreen} 
        options={{
          ...screenOptions,
          headerShown:true,
          headerTitle:'Addresses',
          headerTransparent:false,
          headerStyle:{
            backgroundColor:colors.background
          }
        }}
      />
      <Stack.Screen 
        name="Orders"
        component={OrdersScreen} 
        options={{
          ...screenOptions,
          headerShown:true,
          headerTitle:'Orders',
          headerTransparent:false,
          headerStyle:{
            backgroundColor:colors.background
          }
        }}
      />
      <Stack.Screen 
        name="ExternalGateway"
        component={ExternalGatewayScreen} 
        options={screenOptions}
      />
      <Stack.Screen 
        name="EditProfile"
        component={EditProfileScreen} 
        options={screenOptions}
      />
      <Stack.Screen 
        name="WebPayment"
        component={WebPaymentScreen} 
        options={{
          headerShown:true,
          headerTitle:'Payment',
          headerStyle: {
            backgroundColor: colors.background, // put theme background color
            elevation: 0, // Removes shadow on Android
            shadowOpacity: 0, // Removes shadow on iOS
          },
        }}
      />
    </Stack.Navigator>
  );
}