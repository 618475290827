import React, { useState, useEffect, useRef } from 'react';
import { FlatList, Image, View, Dimensions, Platform, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Video } from 'expo-av';
import { db } from '../../services/firebase';
import styles from './styles';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const FeaturedMedia = () => {
  const navigation = useNavigation();
  const { width } = useSelector((state) => state.app.dimensions);
  const [mediaItems, setMediaItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const flatListRef = useRef(null);
  const scrollViewRef = useRef(null);
  const intervalRef = useRef(null);
  const videoRefs = useRef([]);
  
  const maxWidth = Platform.OS === 'web' ? Math.min(800, width) : width * 0.9;
  const videoHeight = maxWidth * (9 / 16);

  useEffect(() => {
    const unsubscribe = db.collection('featured')
      .orderBy('position', 'desc')
      .onSnapshot(snapshot => setMediaItems(snapshot.docs.map(doc => doc.data())));

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (mediaItems.length > 1 && mediaItems[currentIndex]?.media.type === 'image') {
      startImageRotation();
    }
    return () => clearInterval(intervalRef.current);
  }, [mediaItems, currentIndex]);

  const startImageRotation = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      handleMediaChange((currentIndex + 1) % mediaItems.length);
    }, 3000); // 3 seconds for images
  };

  const handleMediaChange = (index) => {
    const currentMedia = mediaItems[currentIndex]?.media.type;
    
    if (currentMedia === 'video') {
      if (Platform.OS === 'web') {
        videoRefs.current[currentIndex]?.pause();
      } else {
        videoRefs.current[currentIndex]?.stopAsync();
      }
    }
    setCurrentIndex(index);

    if (Platform.OS === 'web') {
      scrollViewRef.current?.scrollTo({ x: index * width, animated: true });
    } else {
      flatListRef.current?.scrollToIndex({ index, animated: true });
    }
  };

  const handleContentPress = (item) => {
    const { screen, id, company } = item.access;
    const params = screen === 'Product' ? { productId: id, companyId: company } : { companyId: id };
    navigation.navigate(screen, params);
  };

  useEffect(() => {
    const mediaType = mediaItems[currentIndex]?.media.type;
    if (mediaType === 'video') {
      if (Platform.OS === 'web') {
        videoRefs.current[currentIndex].currentTime = 0;
        videoRefs.current[currentIndex].play();
      } else {
        videoRefs.current[currentIndex]?.setPositionAsync(0);
        videoRefs.current[currentIndex]?.playAsync();
      }
    }
  }, [currentIndex, mediaItems]);

  const renderMediaItem = (item, index) => (
    <View key={index} style={[styles.mediaContainer, { width, alignItems: 'center' }]}>
      <TouchableOpacity onPress={() => handleContentPress(item)} activeOpacity={0.7}>
        {item.media.type === 'video' ? (
          Platform.OS === 'web' ? (
            <video
              ref={(ref) => (videoRefs.current[index] = ref)}
              src={item.media.source}
              style={{
                width: maxWidth,
                height: videoHeight,
                borderRadius: 10,
                margin: 16,
              }}
              muted
              onEnded={() => handleMediaChange((currentIndex + 1) % mediaItems.length)}
              controls={false}
            />
          ) : (
            <Video
              ref={(ref) => (videoRefs.current[index] = ref)}
              source={{ uri: item.media.source }}
              isMuted
              style={[styles.media, { width: maxWidth, height: videoHeight }]}
              resizeMode="cover"
              shouldPlay={currentIndex === index}
              onPlaybackStatusUpdate={(status) => {
                if (status.didJustFinish) {
                  handleMediaChange((currentIndex + 1) % mediaItems.length);
                }
              }}
            />
          )
        ) : (
          <Image
            source={{ uri: item.media.source }}
            style={[styles.media, { width: maxWidth, height: videoHeight }]}
          />
        )}
      </TouchableOpacity>
    </View>
  );

  return Platform.OS === 'web' ? (
    <ScrollView
      horizontal
      pagingEnabled
      ref={scrollViewRef}
      showsHorizontalScrollIndicator={false}
      onTouchStart={() => clearInterval(intervalRef.current)}
      onScrollEndDrag={() => startImageRotation()}
      style={styles.scrollView}
    >
      {mediaItems.map((item, index) => renderMediaItem(item, index))}
    </ScrollView>
  ) : (
    <FlatList
      data={mediaItems}
      horizontal
      pagingEnabled
      ref={flatListRef}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item, index) => index.toString()}
      renderItem={({ item, index }) => renderMediaItem(item, index)}
      scrollEnabled={mediaItems.length > 1}
      onMomentumScrollEnd={(event) => {
        const newIndex = Math.round(event.nativeEvent.contentOffset.x / width);
        if (newIndex !== currentIndex) {
          handleMediaChange(newIndex);
        }
      }}
      onScrollToIndexFailed={(info) => {
        flatListRef.current.scrollToOffset({ offset: info.averageItemLength * info.index, animated: true });
      }}
      style={styles.flatList}
    />
  );
};

export default FeaturedMedia;