import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Image, FlatList, SafeAreaView } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { useSelector } from 'react-redux';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import styles from './styles';

import ToggleThemeButton from '../../components/ToggleThemeButton';
import LogoutButton from '../../components/LogoutButton';
import PolicyLinks from '../../components/PolicyLinks';
import { useNavigation } from '@react-navigation/native';

const AccountScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    // Only navigate if the user is not authenticated
    if (!user.auth) {
      navigation.replace('Login');
    }
  }, [user.auth, navigation]);

  // If user is not authenticated, return null to prevent rendering the AccountScreen UI
  if (!user.auth) {
    return null;
  }

  const items = [
    {
      key: 'survey',
      component: (
        <TouchableOpacity disabled activeOpacity={.7} style={[styles.row, { backgroundColor: colors.card }]}>
          <View style={[styles.comingSoonContainer]}>
            <Text style={[styles.comingSoonText]}>Coming Soon</Text>
          </View>
          <FontAwesome name="clipboard" size={32} color={colors.brand} />
          <Text style={[styles.rowText, { color: colors.text }]}>Start Survey</Text>
        </TouchableOpacity>
      ),
    },
    {
      key: 'coinSystem',
      component: (
        <TouchableOpacity disabled activeOpacity={.7} style={[styles.row, { backgroundColor: colors.card }]}>
          <View style={[styles.comingSoonContainer]}>
            <Text style={[styles.comingSoonText]}>Coming Soon</Text>
          </View>
          <FontAwesome5 name="wallet" size={32} color={colors.brand} />
          <Text style={[styles.rowText, { color: colors.text }]}>Wallet</Text>
        </TouchableOpacity>
      ),
    },
    {
      key: 'Addresses',
      component: (
        <TouchableOpacity activeOpacity={.7} style={[styles.row, { backgroundColor: colors.card }]} onPress={() => navigation.navigate('Addresses')}>
          <FontAwesome name="home" size={32} color={colors.brand} />
          <Text style={[styles.rowText, { color: colors.text }]}>Addresses</Text>
        </TouchableOpacity>
      ),
    },
    {
      key: 'transactions',
      component: (
        <TouchableOpacity activeOpacity={.7} style={[styles.row, { backgroundColor: colors.card }]} onPress={() => navigation.navigate('Transactions')}>
          <FontAwesome name="credit-card" size={32} color={colors.brand} />
          <Text style={[styles.rowText, { color: colors.text }]}>Transactions</Text>
        </TouchableOpacity>
      ),
    },
    {
      key: 'orders',
      component: (
        <TouchableOpacity activeOpacity={.7} style={[styles.row, { backgroundColor: colors.card }]} onPress={() => navigation.navigate('Orders')}>
          <FontAwesome name="shopping-bag" size={32} color={colors.brand} />
          <Text style={[styles.rowText, { color: colors.text }]}>Orders</Text>
        </TouchableOpacity>
      ),
    },
  ];

  const renderItem = ({ item }) => item.component;

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <FlatList
        ListHeaderComponent={()=>(
          <SafeAreaView>
          <View style={styles.userInfoSection}>
            <View style={styles.userInfoTextContainer}>
              <Text style={[styles.displayName, { color: colors.text }]}>
                {user.auth?.displayName || user.profile?.displayName || 'No Display Name found'}
              </Text>
              <Text style={[styles.email, { color: colors.subText }]}>
                {user.auth?.email || user.auth?.phone || 'No contact information'}
              </Text>
            </View>
            <View style={{flexDirection:'row'}}>
              <ToggleThemeButton style={{marginRight:10, shadowOpacity:0}} />
              <LogoutButton />
              {/* <View style={[styles.coinsContainer, {backgroundColor:colors.card}]}>
                <Text style={[styles.coinsText, {color: colors.subText}]}>Coins: 0</Text>
              </View> */}
            </View>
          </View>
          </SafeAreaView>
        )}
        numColumns={2}
        data={items}
        renderItem={renderItem}
        keyExtractor={(item) => item.key}
        contentContainerStyle={styles.listContainer}
      />
      {/* Policy Links positioned at the bottom */}
      <View style={styles.policyContainer}>
        <PolicyLinks />
      </View>
    </View>
  );
};

export default AccountScreen;