import { StyleSheet, Dimensions } from 'react-native';

export default StyleSheet.create({
  listContainer: {
    alignSelf: 'center',
    marginTop:10
  },
  contentCard: {
    overflow: 'hidden',
    margin: 10,
    paddingBottom: 15,
    borderRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 3,
  },
  media: {
    width: '100%',
    aspectRatio: 1.77,
    borderRadius: 10,
    borderBottomLeftRadius:0,
    borderBottomRightRadius:0,
    marginBottom: 10,
  },
  contentTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  contentDescription: {
    fontSize: 14,
    textAlign: 'center',
  },
  adTextContainer:{
    position:'absolute',
    top:4,
    right:4,
    backgroundColor:'rgba(255,255,255,0.2)',
    height:20,
    width:20,
    borderRadius:10,
    justifyContent:'center',
    alignItems:'center'
  },
  adText:{
    fontSize:10,
    color:'#bbb'
  }
});