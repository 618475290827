import React from 'react';
import { View, Text, Image, StyleSheet, Dimensions } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const CompanyDetails = ({ company }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  const windowWidth = Dimensions.get('window').width;
  const isLargeScreen = windowWidth > 800;
  const maxWidth = 600;

  return (
    <View style={[
      styles.container,
      {
        // backgroundColor: colors.card,
        width: isLargeScreen ? maxWidth : '80%',
        alignSelf: 'center',
      },
    ]}>
      
      {/* Company Name */}
      <Text style={[styles.companyName, { color: colors.text }]}>
        {company.name}
      </Text>

      {/* Stats / Details Row */}
      {/* <View style={styles.detailsContainer}>
        <View style={[styles.detailItem,]}>
          <FontAwesome name="rupee" size={20} color={colors.seconday} />
          <Text style={[styles.detailText, { color: colors.seconday }]}>
            from {company.startingPrice}
          </Text>
        </View>

        <View style={[styles.detailItem,]}>
          <FontAwesome name="shopping-cart" size={20} color={colors.seconday} />
          <Text style={[styles.detailText, { color: colors.seconday }]}>
            purchased {company.purchaseCount}
          </Text>
        </View>
      </View> */}

      {/* Description */}
      <Text style={[styles.description, { color: colors.secondary }]}>
        {company.description || 'No description available.'}
      </Text>

      
    </View>
  );
};

export default CompanyDetails;