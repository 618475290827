import { StyleSheet, StatusBar, Platform } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20, // Offset for iOS
  },
  loadingContainer:{
    marginTop:240
  },
  sectionContainer: {
    marginBottom: 20,
    width: '100%'
  },
  productMedia: {
    width: '100%',
    aspectRatio: 1.77,
    borderRadius: 10,
    marginBottom: 15,
    overflow: 'hidden', // Ensures rounded corners on web
  },
  productInfoContainer:{
    maxWidth:600
  },
  productTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  productDescription: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
  },
  offeringCard: {
    flexDirection:'row',
    justifyContent:'space-between',
    minWidth:320,
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 3,
    marginBottom:20
  },
  offeringInfoContainer:{
    
  },
  offeringTitle: {
    fontSize: 18,
    fontWeight: '600',
  },
  offeringDetails: {

  },
  offeringPriceContainer:{},
  offeringPrice: {
    fontSize: 16,
    fontWeight: '500',
  },
  offeringRetailPrice:{
    color:'#aaa',
    textDecorationLine: 'line-through', 
    textDecorationStyle: 'solid'
  },
  listContainer: {
    paddingBottom:80
  },
});