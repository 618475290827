import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux'; // Assuming you use Redux for authentication state
import { useTheme } from '../../context/ThemeContext'; // Assuming you have a custom ThemeContext
import styles from './styles';

const UserIcon = () => {
  const navigation = useNavigation();
  const isLoggedIn = useSelector((state) => state.app.user.auth); // Replace with your actual state check
  const { theme } = useTheme();
  const { colors } = theme;

  const handlePress = () => {
    navigation.navigate(isLoggedIn ? 'Account' : 'Login');
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePress}
      style={[
        styles.button,
        {
          // shadowColor: colors.shadow || '#000',
          backgroundColor: colors.card,
        }
      ]}
    >
      <View style={styles.buttonIconContainer}>
        <FontAwesome name="user" size={24} color={colors.brand} />
      </View>
    </TouchableOpacity>
  );
};

export default UserIcon;