import React from 'react';
import { Image, Platform, useWindowDimensions, View } from 'react-native';
import { Video } from 'expo-av';
import { useTheme } from '../../context/ThemeContext';
import styles from './styles';

const ProductMedia = ({ media }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  const { width } = useWindowDimensions();

  // Calculate dynamic width and height for the media
  const dynamicWidth = Math.min(width * 0.9, 800); // 90% of screen width, capped at 800px
  const dynamicHeight = dynamicWidth / 1.77; // Calculate height based on 16:9 aspect ratio

  const mediaStyle = [
    styles.productMedia,
    {
      width: dynamicWidth,
      height: dynamicHeight,
      borderColor: colors.card,
      backgroundColor: colors.background,
    },
  ];

  if (media.type === 'video') {
    return Platform.OS === 'web' ? (
      <View style={mediaStyle}>
        <video
          src={media.source}
          style={{ width: '100%', height: '100%' }}
          controls={false}
          muted
          loop
          autoPlay
        />
      </View>
    ) : (
      <Video
        source={{ uri: media.source }}
        style={mediaStyle}
        resizeMode="cover"
        isLooping
        shouldPlay
        isMuted
      />
    );
  }

  return <Image source={{ uri: media.source }} style={mediaStyle} />;
};

export default ProductMedia;