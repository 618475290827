import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 60,
    height: 60,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  cross: {
    margin: 10,
  },
});