// GoogleLoginButton.js
import React, { useState } from 'react';
import { TouchableOpacity, Image, ActivityIndicator, Alert } from 'react-native';
import { useAuthRequest, makeRedirectUri } from 'expo-auth-session';
import { auth } from '../../services/firebase';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';

const GoogleLoginButton = () => {
  const [loading, setLoading] = useState(false);

  // Configure Google OAuth request
  const [request, response, promptAsync] = useAuthRequest({
    clientId: '927853830191-asikif0a7p8tv7i0g7q8mf3p8o45vpfd.apps.googleusercontent.com',
    redirectUri: makeRedirectUri({
      scheme: 'tazzo',
    }),
    scopes: ['profile', 'email'],
  });

  // Handle response
  React.useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;
      const credential = GoogleAuthProvider.credential(id_token);
      setLoading(true);

      // Sign in with Firebase using Google credential
      signInWithCredential(auth, credential)
        .then(() => {
          setLoading(false);
          Alert.alert('Success', 'You are now logged in!');
        })
        .catch((error) => {
          setLoading(false);
          Alert.alert('Error', error.message);
        });
    }
  }, [response]);

  // Button press handler
  const handleGoogleLogin = () => {
    promptAsync();
  };

  return (
    <TouchableOpacity onPress={handleGoogleLogin} disabled={!request || loading} style={{ alignItems: 'center', margin:10 }}>
      {loading ? (
        <ActivityIndicator size="small" color="#0000ff" />
      ) : (
        <Image
          source={require('../../assets/google_button.png')} // Your Google button image
          style={{ width: 172, height: 36 }} // Adjust size as needed
        />
      )}
    </TouchableOpacity>
  );
};

export default GoogleLoginButton;