import React from 'react';
import { TouchableOpacity } from 'react-native';
import { auth } from '../../services/firebase';
import { FontAwesome } from '@expo/vector-icons'; // Import FontAwesome for the logout icon
import { useTheme } from '../../context/ThemeContext'; // Assuming ThemeContext is set up

import styles from './styles';

const LogoutButton = () => {
  const { theme } = useTheme();
  const { colors } = theme;

  const handleLogout = () => {
    auth.signOut()
  }

  return (
    <TouchableOpacity
      onPress={handleLogout}
      style={[
        styles.logoutButton,
        {backgroundColor: colors.card,}
      ]}
    >
      {/* <Text style={[styles.logoutText, { color: colors.subText }]}>Logout</Text> */}
      <FontAwesome name="sign-out" size={24} color={colors.brand} style={[styles.logoutIcon]} />
    </TouchableOpacity>
  );
};

export default LogoutButton;