import { StyleSheet, Platform, StatusBar } from 'react-native';

export default StyleSheet.create({
  container: {
    flexGrow: 1,
    // paddingTop: Platform.OS === 'ios' ? 60 : StatusBar.currentHeight || 20,
    alignItems: 'center',
  },
  listContainer:{
    paddingBottom:60
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  appLogo: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  userInfoSection: {
    flexDirection:'row',
    justifyContent:'space-between',
    marginTop:10
  },
  userInfoTextContainer:{},
  displayName:{
    fontSize:18,
    fontWeight:'bold',
  },
  email: {
    fontSize: 12,
    lineHeight:16
  },
  coinsContainer:{
    padding:10,
    borderRadius:20,
    alignSelf:'center',
  },
  coinsText:{
    textAlign:'center'
  },
  comingSoonContainer:{
    position:'absolute',
    top:-5,
    right:-5,
    paddingVertical:4,
    paddingHorizontal:8,
    backgroundColor:'#FFD700',
    borderRadius:16,
  },
  comingSoonText:{
    fontSize:10
  },
  row: {
    justifyContent:'center',
    alignItems: 'center',
    width:140,
    height:140,
    margin:10,
    marginTop:20,
    borderRadius: 10,
  },
  rowText: {
    fontSize: 14,
    fontWeight:'bold',
    textAlign:'center',
    marginTop:10
  },
  logoutButton: {
    alignItems: 'center',
    padding: 15,
    borderRadius: 8,
    marginTop: 20,
    width: '90%',
  },
  policyContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    alignItems: 'center',
  }
});