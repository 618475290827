import { ofType } from 'redux-observable';
import { fromEventPattern, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { auth, db } from '../../../services/firebase';
import { subscribeUserState, userStateUpdate, userStateError } from '../reducers/userSlice';

// Helper function to serialize Firebase auth data
const serializeFirebaseUser = (user) => {
  if (!user) return null;

  return {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    phoneNumber: user.phoneNumber,
    emailVerified: user.emailVerified,
  };
};

const userEpic = (action$) =>
  action$.pipe(
    ofType(subscribeUserState.type),
    switchMap(() =>
      fromEventPattern(
        (handler) => auth.onAuthStateChanged(handler),
        (_, unsubscribe) => unsubscribe
      ).pipe(
        switchMap((authData) => {
          if (authData) {
            const uid = authData.uid;
            const serializedAuthData = serializeFirebaseUser(authData); // Serialize auth data

            return fromEventPattern(
              (handler) => db.collection('users').doc(uid).onSnapshot(handler),
              (_, unsubscribe) => unsubscribe()
            ).pipe(
              map((doc) => {
                const profileData = doc.exists ? doc.data() : null;
                return userStateUpdate({ auth: serializedAuthData, profile: profileData });
              }),
              catchError((error) => of(userStateError(error.message)))
            );
          } else {
            // If the user logs out, reset the state to null
            return of(userStateUpdate({ auth: null, profile: null }));
          }
        }),
        catchError((error) => of(userStateError(error.message)))
      )
    )
  );

export default userEpic;