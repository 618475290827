import React, { useEffect, useState } from 'react';
import { View, FlatList, StatusBar } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { useNavigation, useRoute } from '@react-navigation/native';
import { db } from '../../services/firebase';
import styles from './styles';

import LogoHeader from '../../components/LogoHeader';
import CompanyFeaturedMedia from '../../components/CompanyFeaturedMedia';
import CompanyDetails from '../../components/CompanyDetails';
import CategoryFilter from '../../components/CategoryFilter';
import CompanyContentList from '../../components/CompanyContentList';

const CompanyScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  const route = useRoute();
  const { companyId } = route.params;
  const [company, setCompany] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterType, setFilterType] = useState('products');
  const [sortOrder, setSortOrder] = useState('purchaseCount');
  const [orderDirection, setOrderDirection] = useState('desc');

  useEffect(() => {
    if (!navigation.canGoBack()) {
      navigation.reset({
        index: 1,
        routes: [
          { name: 'Home' },
          { name: 'Company', params: { ...route.params } },
        ],
      });
    }
  }, [navigation, companyId]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyDoc = await db.doc(`/companies/${companyId}`).get();
        if (companyDoc.exists) {
          const companyData = companyDoc.data();
          setCompany({ id: companyDoc.id, ...companyData });
          setSelectedCategory(companyData.productListConfig.defaultCategory);
          setSortOrder(companyData.productListConfig.sortOrder);
          setOrderDirection(companyData.productListConfig.orderDirection);
        } else {
          console.error('Company does not exist');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  const renderSectionItem = ({ item }) => <View style={styles.sectionContainer}>{item.component}</View>;

  const sections = [
    { key: 'logo', component: <LogoHeader companyLogo={company?.logo} /> },
    { key: 'featured', component: company && <CompanyFeaturedMedia company={company} /> },
    { key: 'companyDetails', component: company && <CompanyDetails company={company} /> },
    {
      key: 'categories',
      component: company?.productListConfig?.showCategories && (
        <CategoryFilter
          company={company}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          filterType={filterType}
          setFilterType={setFilterType}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
        />
      ),
    },
    {
      key: 'companyContentList',
      component: (
        company && (
          <CompanyContentList
            company={company}
            category={selectedCategory}
            filterType={filterType}
            sortOrder={sortOrder}
            orderDirection={orderDirection}
          />
        )
      ),
    },
  ];

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <FlatList
        data={sections}
        renderItem={renderSectionItem}
        keyExtractor={(item) => item.key}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      />
    </View>
  );
};

export default CompanyScreen;