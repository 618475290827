import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import styles from './styles'; // AddressCard-specific styles
import { useTheme } from '../../context/ThemeContext';

const AddressCard = ({ address, onEdit, onDelete }) => {
  const { theme } = useTheme();
  const { colors } = theme;

  return (
    <View style={[styles.card, { backgroundColor: colors.card }]}>
      <View style={styles.cardContent}>
        {/* Address Lines */}
        <Text style={[styles.addressLine, { color: colors.text }]}>
          {address.addressLine1}
        </Text>
        {address.addressLine2 ? (
          <Text style={[styles.addressLine, { color: colors.subText }]}>
            {address.addressLine2}
          </Text>
        ) : null}

        {/* City, State, Country */}
        <Text style={[styles.location, { color: colors.subText }]}>
          {`${address.city}, ${address.state}`}
        </Text>
        <Text style={[styles.country, { color: colors.subText }]}>
          {address.country}
        </Text>

        {/* Phone Number */}
        <Text style={[styles.phone, { color: colors.text }]}>
          Phone: {address.phone}
        </Text>
      </View>

      {/* Actions */}
      <View style={styles.cardActions}>
        <TouchableOpacity onPress={onEdit} style={styles.iconButton}>
          <FontAwesome name="pencil" style={[styles.icon, { color: colors.primary }]} />
        </TouchableOpacity>
        <TouchableOpacity onPress={onDelete} style={styles.iconButton}>
          <FontAwesome name="trash" style={[styles.icon, { color: 'red' }]} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default AddressCard;