// WebPaymentScreen.js

import React, { useState } from 'react';
import { View, ActivityIndicator, Alert, Linking, Platform, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import { useRoute, useFocusEffect } from '@react-navigation/native';
import { db } from '../../services/firebase';
import { useTheme } from '../../context/ThemeContext';

const WebPaymentScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const route = useRoute();
  const { transactionPath } = route.params;
  const [gatewayLink, setGatewayLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState('');

  useFocusEffect(
    React.useCallback(() => {
      if (!transactionPath) {
        Alert.alert("Error", "Invalid transaction path.");
        setLoading(false);
        return;
      }

      const unsubscribe = db.doc(transactionPath).onSnapshot(
        (transactionDoc) => {
          if (transactionDoc.exists) {
            const transactionData = transactionDoc.data();
            const status = transactionData.status || 'Loading...';
            setTransactionStatus(status);
            
            if (status === 'Created' && transactionData.gatewayLink) {
              setGatewayLink(transactionData.gatewayLink);
            } else {
              setGatewayLink('');
            }
          } else {
            Alert.alert("Error", "Transaction not found.");
            setTransactionStatus('Error');
            setGatewayLink('');
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error listening to transaction:', error);
          Alert.alert("Error", "Failed to listen to transaction updates.");
          setTransactionStatus('Error');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }, [transactionPath])
  );

  const handleNavigationStateChange = (event) => {
    const url = event.url;

    if (url.startsWith('about:')) {
      return false;
    }

    if (Platform.OS !== 'web' && url.includes('://') && !url.startsWith('http') && !url.startsWith('https')) {
      Linking.openURL(url).catch((err) => {
        console.error("Failed to open external URL:", err);
        Alert.alert("Error", "Unable to open the link.");
      });
      return false;
    }

    return true;
  };

  const renderStatusMessage = () => {
    switch (transactionStatus) {
      case 'Creating':
        return (
          <View style={styles.messageContainer(colors)}>
            <ActivityIndicator size="large" color={colors.primary} />
            <Text style={styles.messageText(colors)}>Creating, please wait...</Text>
          </View>
        );
      case 'Created':
        return gatewayLink ? null : (
          <View style={styles.messageContainer(colors)}>
            <ActivityIndicator size="large" color={colors.primary} />
            <Text style={styles.messageText(colors)}>Loading payment link...</Text>
          </View>
        );
      case 'Failed':
        return (
          <View style={styles.messageContainer(colors)}>
            <Text style={styles.errorText(colors)}>Transaction failed. Please try again.</Text>
          </View>
        );
      case 'Error':
        return (
          <View style={styles.messageContainer(colors)}>
            <Text style={styles.errorText(colors)}>An error occurred. Please contact support.</Text>
          </View>
        );
      default:
        return (
          <View style={styles.messageContainer(colors)}>
            <ActivityIndicator size="large" color={colors.primary} />
            <Text style={styles.messageText(colors)}>Loading transaction details...</Text>
          </View>
        );
    }
  };

  if (loading || (transactionStatus === 'Creating' || !gatewayLink)) {
    return renderStatusMessage();
  }

  return Platform.OS === 'web' ? (
    <View style={styles.iframeContainer(colors)}>
      <iframe
        src={gatewayLink}
        style={{
          width: '400px',
          height: '100%',
          border: 'none',
          backgroundColor: colors.background,
        }}
        title="Payment Gateway"
      />
    </View>
  ) : (
    <WebView
      source={{ uri: gatewayLink }}
      style={{ flex: 1, backgroundColor: colors.background }}
      onShouldStartLoadWithRequest={handleNavigationStateChange}
      startInLoadingState={true}
      renderLoading={() => (
        <View style={styles.messageContainer(colors)}>
          <ActivityIndicator size="large" color={colors.primary} />
          <Text style={styles.messageText(colors)}>Loading transaction details...</Text>
        </View>
      )}
    />
  );
};

const styles = {
  iframeContainer: (colors) => ({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  }),
  messageContainer: (colors) => ({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  }),
  messageText: (colors) => ({
    color: colors.text,
    marginTop: 20,
    fontSize: 16,
  }),
  errorText: (colors) => ({
    color: colors.error || 'red',
    marginTop: 20,
    fontSize: 16,
    fontWeight: 'bold',
  }),
};

export default WebPaymentScreen;