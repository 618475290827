import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  Keyboard,
  Image,
  useColorScheme,
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback
} from 'react-native';
import { auth } from '../../services/firebase';
import styles from './styles';
import { Light, Dark } from '../../theme';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';

import GoogleLoginButton from '../../components/GoogleLoginButton';

const LoginScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const user = useSelector((state) => state.app.user);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const colorScheme = useColorScheme();
  const theme = colorScheme === 'dark' ? Dark : Light;

  // Destructure nextScreen from route.params
  const { nextScreen } = route.params || {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordInputRef = useRef(null);

  const handleLogin = async () => {
    if (!emailRegex.test(email)) {
      Alert.alert("Invalid Email", "Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      Alert.alert("Login Failed", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.auth) {
      if (nextScreen) {
        navigation.goBack();
      } else {
        navigation.replace('Account');
    }
    }
  }, [user.auth]);

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <KeyboardAvoidingView
        style={[styles.container, { backgroundColor: theme.colors.background }]}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <View style={[styles.formContainer, { backgroundColor: theme.colors.card }]}>
          <Image source={require('../../assets/logo.jpg')} style={styles.logo} />

          <TextInput
            placeholder="Email"
            placeholderTextColor={theme.colors.border}
            style={[
              styles.input,
              { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
            ]}
            value={email}
            onChangeText={setEmail}
            keyboardType="email-address"
            autoCapitalize="none"
            returnKeyType="next"
            onSubmitEditing={() => passwordInputRef.current && passwordInputRef.current.focus()}
          />

          <TextInput
            placeholder="Password"
            placeholderTextColor={theme.colors.border}
            style={[
              styles.input,
              { backgroundColor: theme.colors.background, color: theme.colors.text, borderColor: theme.colors.border }
            ]}
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            autoCapitalize="none"
            returnKeyType="done"
            onSubmitEditing={handleLogin}
            ref={passwordInputRef}
          />

          <TouchableOpacity
            style={[styles.button, { backgroundColor: theme.colors.notification }]}
            onPress={handleLogin}
            disabled={loading}
          >
            <Text style={[styles.buttonText, { color: theme.colors.text }]}>{loading ? "Logging in..." : "Log In"}</Text>
          </TouchableOpacity>

          {!nextScreen && (
            <Text style={[styles.signupText, { color: theme.colors.text }]}>
              Don't have an account?{' '}
              <Text style={[styles.signupLink, { color: theme.colors.notification }]} onPress={() => navigation.navigate("SignUp")}>
                Sign Up
              </Text>
            </Text>
          )}

          <GoogleLoginButton />

          <Text style={[styles.termsText, { color: theme.colors.text }]}>
            By logging in, you agree to our{' '}
            <Text style={[styles.link, { color: theme.colors.notification }]} onPress={() => Alert.alert("Terms of Use", "Terms content here")}>
              Terms of Use
            </Text>{' '}
            and{' '}
            <Text style={[styles.link, { color: theme.colors.notification }]} onPress={() => Alert.alert("Privacy Policy", "Privacy content here")}>
              Privacy Policy
            </Text>.
          </Text>
        </View>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};

export default LoginScreen;