import React, { useEffect } from 'react';
import { View, Image, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import styles from './styles';
import logo from '../../assets/logo.jpg';
import { subscribeDimensions } from './reducers/dimensionsSlice';
import { subscribeUserState } from './reducers/userSlice';

const LoadApp = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();


  useEffect(() => {
    dispatch(subscribeDimensions());
    dispatch(subscribeUserState());
    navigation.replace('Home')
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <Image source={logo} style={[styles.logo, {width: 120, height: 120}]} />
      <ActivityIndicator size="large" color="#FF6347" style={styles.loadingIndicator} />
    </View>
  );
};

export default LoadApp;