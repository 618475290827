import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, TextInput, Alert, ActivityIndicator, FlatList } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { db } from '../../services/firebase';
import LogoHeader from '../../components/LogoHeader';
import LogoutButton from '../../components/LogoutButton';
import PolicyLinks from '../../components/PolicyLinks';
import CreateTransactionModal from '../../components/CreateTransactionModal';
import styles from './styles';

const PaymentScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  const navigation = useNavigation();
  const route = useRoute();
  const { companyId, productId, offeringId } = route.params;

  const user = useSelector((state) => state.app.user);
  const [offer, setOffer] = useState(null);
  const [product, setProduct] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState(user?.auth?.displayName || user?.profile?.displayName || '');
  const [userPhone, setUserPhone] = useState(user?.auth?.phoneNumber || '');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const MAX_NAME_LENGTH = 50;
  const MAX_PHONE_LENGTH = 15;
  const phoneRegex = /^\+[1-9]\d{0,14}$/; // Allows + at start, followed by 1-14 digits
  const nameRegex = /^[A-Za-z.]*$/; // Allows only letters and periods

  useEffect(() => {
    const fetchOfferingDetails = async () => {
      try {
        const offeringDoc = await db.doc(`/companies/${companyId}/products/${productId}/offerings/${offeringId}`).get();
        const productDoc = await db.doc(`/companies/${companyId}/products/${productId}`).get();
        const companyDoc = await db.doc(`/companies/${companyId}`).get();

        if (offeringDoc.exists) {
          setOffer(offeringDoc.data());
          setProduct(productDoc.data());
          setCompany(companyDoc.data());
        } else {
          Alert.alert('Error', 'Offering not found');
          navigation.goBack();
        }
      } catch (error) {
        console.error('Error fetching offering:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOfferingDetails();
  }, [companyId, productId, offeringId, navigation]);

  const handleLoginRedirect = () => {
    setUserName('');
    setUserPhone('');
    navigation.navigate('Login', { nextScreen: 'Payment', params: route.params });
  };

  const handlePayment = (method) => {
    if (isPaymentEnabled) {
      setSelectedPaymentMethod(method);
      setIsModalVisible(true);
    } else {
      Alert.alert('Incomplete Information', 'Please ensure your name and a valid contact number are filled.');
    }
  };

  const handleModalClose = (successResponse) => {
    setIsModalVisible(false);
    setSelectedPaymentMethod(null)
    if (successResponse) {
      navigation.navigate(selectedPaymentMethod.screen, {
        transactionPath: successResponse.transactionPath
      });
    }
  };

  const handleNameChange = (text) => {
    if (nameRegex.test(text)) {
      setUserName(text.slice(0, MAX_NAME_LENGTH));
    }
  };

  const handlePhoneChange = (text) => {
    if (phoneRegex.test(text) || text === '+') {
      setUserPhone(text.slice(0, MAX_PHONE_LENGTH));
    }
  };

  const handlePhoneFocus = () => {
    if (userPhone === '') {
      setUserPhone('+');
    }
  };

  const isPaymentEnabled = (userName && phoneRegex.test(userPhone)) || user?.auth;

  const renderPaymentMethodButton = (method) => (
    <TouchableOpacity
      key={method.name}
      style={[
        styles.paymentButton,
        {
          backgroundColor: colors.card,
          opacity: isPaymentEnabled ? 1 : 0.6,
        },
      ]}
      onPress={() => handlePayment(method)}
      disabled={!isPaymentEnabled}
    >
      <Text style={[styles.paymentButtonText, { color: colors.text }]}>{method.name}</Text>
    </TouchableOpacity>
  );

  if (loading) {
    return <ActivityIndicator size="large" color={colors.primary} style={styles.loading} />;
  }

  if (!offer) {
    return <Text style={[styles.errorText, { color: colors.text }]}>Offer not found</Text>;
  }

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <FlatList
        ListHeaderComponent={
          <View>
            <LogoHeader companyLogo={company?.logo}/>
            <View style={[styles.offerContainer, { backgroundColor: colors.card }]}>
              <Text style={[styles.offerName, { color: colors.primary }]}>{offer.name}</Text>
              <Text style={[styles.offerDetails, { color: colors.text }]}>Product: {product.name}</Text>
              <Text style={[styles.offerDetails, { color: colors.text }]}>Company: {company.name}</Text>
              <Text style={[styles.offerPrice, { color: colors.notification }]}>Amount: ${offer.price.amount}</Text>
            </View>

            {user.auth ? (
              <View style={[styles.userContainer, { borderColor: colors.subText }]}>
                <UserInfo user={user} colors={colors} />
                <LogoutButton />
              </View>
            ) : (
              <View style={styles.authContainer}>
                <TouchableOpacity style={[styles.loginButton, { backgroundColor: colors.brand }]} onPress={handleLoginRedirect}>
                  <Text style={[styles.loginText, { color: colors.text }]}>Login</Text>
                </TouchableOpacity>
              </View>
            )}

            {!user.auth && (
              <>
                <View style={styles.divider}>
                  <Text style={[styles.dividerText, { color: colors.subText }]}>or enter details to continue payment</Text>
                </View>
                <View style={styles.formContainer}>
                  <TextInput
                    placeholder="Your Name"
                    placeholderTextColor={colors.border}
                    style={[styles.input, { backgroundColor: colors.card, color: colors.text }]}
                    value={userName}
                    onChangeText={handleNameChange}
                    maxLength={MAX_NAME_LENGTH}
                  />
                  <TextInput
                    placeholder="+91 98765 43210"
                    placeholderTextColor={colors.border}
                    style={[styles.input, { backgroundColor: colors.card, color: colors.text }]}
                    value={userPhone}
                    onChangeText={handlePhoneChange}
                    onFocus={handlePhoneFocus}
                    keyboardType="phone-pad"
                    maxLength={MAX_PHONE_LENGTH}
                  />
                </View>
              </>
            )}

            <Text style={[styles.payUsingText, { color: colors.subText }]}>Pay Using:</Text>
          </View>
        }
        data={offer.paymentMethods}
        renderItem={({ item }) => renderPaymentMethodButton(item)}
        keyExtractor={(item) => item.name}
        contentContainerStyle={styles.container}
      />
      <View style={styles.footerContainer}>
        <PolicyLinks />
      </View>
      <CreateTransactionModal
        visible={isModalVisible}
        onClose={handleModalClose}
        user={
          (user?.auth) ? 
          {...user?.auth, displayName: user?.auth?.displayName || user?.profile?.displayName, userExists:true} : 
          (userName === '' || userPhone === '') ? null : {displayName:userName, phoneNumber:userPhone, userExists:false}
        }
        companyId={companyId}
        productId={productId}
        offeringId={offeringId}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    </View>
  );
};

// UserInfo Component for displaying logged-in user details
const UserInfo = ({ user, colors }) => (
  <View style={styles.userInfoContainer}>
    <Text style={[styles.userInfoText, { color: colors.text }]}>{user?.auth?.displayName || user?.profile?.displayName || 'No Name'}</Text>
    <Text style={[styles.userInfoSubtext, { color: colors.subText }]}>{user?.auth?.email || 'No Email'}</Text>
    <Text style={[styles.userInfoSubtext, { color: colors.subText }]}>{user?.auth?.phoneNumber || 'No Phone Number'}</Text>
  </View>
);

export default PaymentScreen;