import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator, Alert, Text } from 'react-native';
import { useTheme } from '../../context/ThemeContext';
import { FontAwesome } from '@expo/vector-icons';
import { db } from '../../services/firebase';
import styles from './styles';
import OrderCard from '../../components/OrderCard';

const OrdersScreen = () => {
  const { theme } = useTheme();
  const { colors } = theme;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const userId = 'yourUserId'; // Replace this with dynamic user ID logic
        const ordersSnapshot = await db.collection(`/users/${userId}/orders`).get();
        const ordersData = ordersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
        Alert.alert('Error', 'Failed to load orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const renderOrderItem = ({ item }) => <OrderCard order={item} />;

  if (loading) {
    return (
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  if (orders.length === 0) {
    return (
      <View style={[styles.emptyContainer, { backgroundColor: colors.background }]}>
        <FontAwesome name="shopping-bag" style={[styles.emptyIcon, { color: colors.text }]} />
        <Text style={[styles.emptyText, { color: colors.text }]}>
          No orders found.
        </Text>
      </View>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Text style={[styles.title, { color: colors.text }]}>Your Orders</Text>
      <FlatList
        data={orders}
        renderItem={renderOrderItem}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.listContainer}
      />
    </View>
  );
};

export default OrdersScreen;